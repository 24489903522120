import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getBonus,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  setIsVisible,
  fetchBonusList,
  updateBonusStatus,
  fetchSportsList,
  getCouponCode,
  getBonuseCode,
  setBonusCode,
  setFormData,
  setDocsData,
  setCreateBonusErrors,
  setDisableBtn,
  setCreateBonus,
  setIsDialogOpen,
  setBonusAlert,
  setIsBonusCreated,
  AllowTax,
  getProviderName,
  setEditSaveDisable,
  setIsBonusTableVisible,
  setIsBonusTableLoaded,
  setOpenDialog,
  getAffiliateNames,
  getPaymentMethod,
} from "./createbonusnewslice";
import BonusTable from "./createbonustable";
import {
  getDateTimeFormate,
  cleanObject,
  generateCode,
  sortingFilter,
  convertStartDatePickerTimeToMySQLTime,
  convertEndDatePickerTimeToMySQLTime,
  MomentUTCDateTimeToday,
  extractDate,
} from "../../sharedfiles/helper";
import MultiSelectDDL from "../../sharedfiles/MultiSelectDDL";
import Vpagination from "../../sharedfiles/Vpagination";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Input from "../Common/common_inputs/Input";
import Select from "../Common/common_inputs/Select_DDL";
import Datepicker from "../Common/common_inputs/DatePicker";
import {
  bonusTypeData,
  dateTypeData,
  inputFieldData,
  selectFieldData,
  errors,
  freebetFields,
  nonFreebetFields,
  filterDdl,
  allocationCriteria,
  optDdlData,
  autoDeposit,
  autoRegister,
  oneTwoCut,
  bonusAllocationData,
  releaseCriteriaData,
  referalAllocationData,
  referalField,
  goodWillBonus,
  rolloverTimesData,
  rolloverBAdata,
  bonusCreationData,
  bonusAllocationIpData,
  bonusUsageData,
  tagsType,
  cashbackBonus,
  CasbackBetType,
} from "../Common/data/createBonus/createBonusData.js";
import AllocationComponent from "./AllocationComponent";
import BonusCodePop from "./BonusCodePop";
import { BONUS_CONSTANTS, COMMON_CONSTANTS } from "../../sharedfiles/Constants";
import Tooltips from "../Common/Tooltip";
import { Bonusing, COMMON_MSGS } from "../../sharedfiles/tooltipMsgs";
import MultipleSelectDDL from "../Common/common_inputs/MultipleSelectDDL.js";
import BonusUsageDDL from "./BonusUsageDDLs.js";
import { getAllCountriesBySport, setCountries, setCountryInBonus, setEventBySportCountryLeague, setEventInBonus, setEvents, setIsCountriesLoading, setIsEventLoading, setIsLeagueLoading, setLeagues, setLeaguesInBonus } from "../BoostedOdds/boostedOddsSlice.js";
import { getAllCasinoDropdowns, getProviderNamesDropdown } from "../BetHistory/betHistorySlice.js";
import BonusDDLs from "./BonusDDLs.js";
import Skelton from "../Common/skelton/Skelton.js";

class CreateBonusNew extends React.Component {
  constructor(props) {
    let timer = null;
    super(props);
    this.myRef = React.createRef();
    this.state = {

      applicableType: "",
      applyExpiry: "",
      bonusAliases: [],
      bonusCode: "",
      bonusDescription: "",
      depositCriteria: "",
      instantBonus: "",
      maxBonus: "",
      // minDeposit: 0,
      minBonus: "",
      option: "",
      startDate: "",
      tags: "",
      validUntil: "",
      wagerBonus: "",
      wagerCriteria: 20,
      applicableSports: "",
      validityExpiry: "",
      promoCode: "",
      settlementType: "",
      bonusPercentage: "",
      bonusErr: false,
      bonusErrMsg: "",
      oddsThreshold: 1.7,
      freebetStatus: "",
      sportsSelected: [],
      bonusType: "",
      bonusName: "",
      hideFields: true,
      clearAll: false,
      freebetAmount: "",
      ids: [],
      pdfBody: [],
      csvData: [],
      statusType: "ACTIVE",
      filterBonusType: "",
      filterBonusStatus: "",
      filterAllocationType: "",
      payloadData: {
        bonusType: "",
        statusType: "",
        allocationType: "",
      },
      bonusId: "",
      editBonusData: {},
      validDate: '',
      openEditBox: false,
      manageIDs: {
        id: '',
        bonusCode: '',
        bonusStatus: '',
      },
      statusValue: "",
      freebetAmountErrMsg: "",
      freebetnameErrMsg: "",
      oddsThresholdMsg: "",
      freebetstsErrMsg: "",
      sportErrMsg: "",
      StartDateErrMsg: "",
      ValidtillErrMsg: "",
      hidefieldsDeposit: true,
      errors: [],
      bonusTypeError: false,
      sportsError: false,
      sportId: [],
      fieldsData: [...inputFieldData],
      hideBottom: true,
      result: "",
      bonusAmount: "",
      oddsThresh: 1.2,
      minStake: 100,
      numberOfLegs: 4,
      bonusPayout: "",

      Referrer: "",
      Referee: "",
      Recurring: "",
      channel: "",
      releaseCriteria: "",
      minStakeRef: "",
      minDepositRef: "",
      bonusAmountRef: "",
      maxwinError: false,
      disableBtn: false,
      searchData: "",
      timer: null,
      openPopUp: false,
      bonusCodeErr: false,
      focusState: false,
      maxWinPercent: 100,
      maxWinAmount: 0,
      sportsErr: false,
      depoReleaseCriteria: "",
      depoUnlockedBonus: 0,
      depoLockedBonus: 100,
      rolloverTime: 5,
      bonusCreateData: [...bonusCreationData],
      bonusAllocationFields: [...bonusAllocationIpData],
      bonusUsageFields: [...bonusUsageData],

      unlockApplicableType: '',
      unlockNumberOfLegs: 4,
      unlockOddsThreshold: 1.5,

      unlocksportsSelected: [],
      unlockApplicableSportsIds: [],
      clearUnlockSports: false,
      unlockSportErr: false,

      rollNumberOfLegs: 4,
      rollOddsThreshold: 1.5,
      rollOverApplicableType: '',
      rollOverSportId: [],
      rollOversportsSelected: [],
      clearRollOverSports: false,
      rollOverSportErr: false,

      wagerAppTypeErr: false,
      rollAppTypeErr: false,
      editValidUntil: '',
      editDateErr: false,
      allowTags: [],
      denyTags: [],
      allowTagsClearAll: false,
      denyTagsClearAll: false,
      providerName: [],
      shouldProviderFalse: false,
      providerErr: false,
      releaseProviders: [],
      releaseProvidersReset: false,
      gameName: [],
      gameNameReset: false,
      gameNameErr: false,
      releaseGameName: [],
      releaseGameNameReset: false,
      releaseProviderErr: false,
      shouldRollFalse: false,
      rollGameNameFalse: false,
      rollOverProviderReset: false,
      rollOverGameNameReset: false,
      rollOverProvider: [],
      rollOverGameName: [],

      bonusAppFor: '',
      bettingType: '',
      cashbackFrequency: '',
      cashbackType: '',
      isChecked: false,
      cashbackMinStake: '',
      cashbackMinOdds: '',
      cashbackNoLegs: '',
      cashbackPercentage: '',
      maxCashbackBonus: '',
      cashbackBetType: [],
      bonusSportSelection: [],
      bonusFieldSelection: [],
      countries: [],
      leagues: [],
      events: [],
      clearCashBackBetType: true,
      affilateNames: [],
      resetAffiliateNames: false,
      isEventSelected: false,
      isToggled: false,
      paymentMethod: [],
      isPaymentMethodReset: false,
    };
    this.setSelected = this.setSelected.bind(this);
    this.setClear = this.setClear.bind(this);
    this.setIds = this.setIds.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.updateTableToDocs = this.updateTableToDocs.bind(this);
    this.callApi = this.callApi.bind(this);
    this.handleActiveDeactive = this.handleActiveDeactive.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setValues = this.setValues.bind(this);
    this.setDates = this.setDates.bind(this);
    this.fetchAll = this.fetchAll.bind(this);
    this.depoFunction = this.depoFunction.bind(this);
    this.filterTableData = this.filterTableData.bind(this);
    this.setInputs = this.setInputs.bind(this);
    this.setRandomCode = this.setRandomCode.bind(this);
    this.onReject = this.onReject.bind(this);
    this.onAccept = this.onAccept.bind(this);
    this.setUnlockSportSelected = this.setUnlockSportSelected.bind(this);
    this.setUnlockSportId = this.setUnlockSportId.bind(this)
    this.setUnlockSportClear = this.setUnlockSportClear.bind(this)
    this.setRollOverSportId = this.setRollOverSportId.bind(this);
    this.setRollOverSportSelected = this.setRollOverSportSelected.bind(this)
    this.setRollOverSportClear = this.setRollOverSportClear.bind(this)
    this.handleValidDate = this.handleValidDate.bind(this)
    this.onEditSubmit = this.onEditSubmit.bind(this)
    this.handleEditClose = this.handleEditClose.bind(this)
    this.handleEditOpen = this.handleEditOpen.bind(this)
  }
  handleChange(event) {
    const { name, value } = event.target;
    const numberReg = /^[0-9]+$/;

    if (name != "instantBonus") {
      this.setState({ [name]: value });
    }

    if (name === "instantBonus") {
      // if (numberReg.test(value)) {
      value <= 100 &&
        this.setState({
          instantBonus: value,
          wagerBonus: 100 - value,
        });
      // }
    }
  }
  headers = [
    [
      "Bonus Type",
      "Bonus Code",
      "Bonus",
      "Wager Bonus",
      "Instant Bonus",
      "Max Win Amount",
      "Max Bonus ",
      "Rollover Times",
      "Bonus Criteria",
      "Cashback Minimum Stake",
      "Cashback Minimum Odds",
      "Cashback No Of Legs",
      "Cashback % ",
      "Max. Cashback Bonus Amount",
      "Bet Type",
      "Cashback bonus Applicable For",
      "Cashback Betting Type ",
      "Cashback Frequency",
      "Cashback Type",
      "Add to Withdrawable balance",
      "Add Cut Bonus to Withdrawable",
      "Bonus Status",
      "Created Date",
      "Apply Expiry",
      "Updated Date",
      "Start Date",
      "Valid Until",
      "Max Winning %",
      "Deposit Rel %",
      "Deposit Criteria",
      "Wager Criteria",
      "Min Deposit",
      "Opt-In",
      "Bonus Description",
      "Bonus Sports",
      "Unlocked Applicable Sports",
      "Odds Threshold",
      "Unlocked Odds Threshold",
      "Applicable To",
      "Unlocked Applicable Type",
      "Tags",
      "Brand",
      "Number Of Legs",
      "RC Number Of Legs",
      "Min Stake Amount",
      "Bonus Payout %",
      "Referrer %",
      "Referee %",
      "Recurring Referrer %",
      "Affiliate User Names",
      "Payment Methods",
    ],
  ];
  exportPDF() {
    const title = "Create Bonus Report";
    const size = "A2"
    exportPDFC(title, this.headers, this.state.pdfBody, "", "", size);
  }
  callApi(pageNumber, itemsPerPage, data) {
    this.props.dispatch(setIsBonusTableLoaded(false))
    this.props.dispatch(setIsVisible(false));
    const payload = {
      bonusType: data !== undefined ? data.bonusType : "",
      statusType: data !== undefined ? data.statusType : "",
      allocationType: data !== undefined ? data.allocationType : "",
      pageNumber: pageNumber - 1,
      itemsPerPage: itemsPerPage,
    };
    this.props.dispatch(fetchBonusList(payload));

  }

  updateTableToDocs(key = "", sortType = "", keyType = "") {
    setTimeout(() => {
      const data =
        this.props.bonusData &&
        Object.keys(this.props?.bonusData).length > 0 &&
        this.props?.bonusData?.data &&
        Array.isArray(this.props?.bonusData?.data) &&
        this.props?.bonusData?.data?.length > 0;
      const pdfBody = data && sortingFilter(this.props?.bonusData?.data, { key, sortType, keyType }).map((item) => [
        item.bonusType,
        item.bonusCode,
        item.bonusType == "REFERRAL" ? item.maxBonus : 0,
        item.wagerBonus,
        item.instantBonus,
        item.maxWinAmount,
        item.maxBonus,
        item.rollOverMultiplier,
        item.bonusCriteria,
        item.minStakeAmount || "-",
        item.minCashBackOdds || '-',
        item.minCashBackLegs || '-',
        item.cashBackPercentage || '-',
        item.maxCashBackAmount || '-',
        item.cashBackType || '-',
        item.cashBackBetType || '-',
        item.cashBackBetStatus || '-',
        item.cashBackFrequency || '-',
        item.cashBackType || '-',
        item.addToWithdrawableAmount ? "true" : "false",
        item.isCutBonusWithdrawable ? "true" : "false",
        item.bonusStatus,
        item.createdDate ? getDateTimeFormate(item.createdDate) : "-",
        item.applyExpiry,
        item.updatedDate ? getDateTimeFormate(item.updatedDate) : "-",
        item.startDate ? getDateTimeFormate(item.startDate) : "-",
        item.validUntil ? getDateTimeFormate(item.validUntil) : "-",
        item.maxWinPercentage,
        item.bonusPercentage,
        item.depositCriteria,
        item.wagerCriteria,
        item.minDeposit,
        item.optIn,
        item.bonusDescription,
        "",
        // item?.bonusSports?.applicableSports?.map((item,ind) => ind == 0 ? item : `, ${item}`),
        "",
        item.oddsThreshold,
        item.unlockOddsThreshold,
        item.applicableType,
        item.unlockApplicableType,
        Array.isArray(item?.tags) &&
        item?.tags?.length > 0 &&
        item?.tags != null &&
        item.tags[0],
        item.brand == "starpunter" ? "StarPunter" : item.brand,
        item.numberOfLegs,
        item.unlockNumberOfLegs,
        item.minStake,
        item.bonusPayoutPercentage,
        item.referrerPercentage,
        item.refereePercentage,
        item.recReferrerPercentage,
      ]);
      
      const csvData = data && sortingFilter(this.props?.bonusData?.data, { key, sortType, keyType }).map((item) => [
        item.bonusType,
        item.bonusCode,
        item.bonusType == "REFERRAL" ? item.maxBonus : 0,
        item.wagerBonus,
        item.instantBonus,
        item.maxWinAmount,
        item.maxBonus,
        item.rollOverMultiplier,
        item.bonusCriteria,
        item.minStakeAmount || "-",
        item.minCashBackOdds || '-',
        item.minCashBackLegs || '-',
        item.cashBackPercentage || '-',
        item.maxCashBackAmount || '-',
        item.cashBackType || '-',
        item.cashBackBetType || '-',
        item.cashBackBetStatus || '-',
        item.cashBackFrequency || '-',
        item.cashBackType || '-',
        item.addToWithdrawableAmount ? "true" : "false",
        item.isCutBonusWithdrawable ? "true" : "false",
        item.bonusStatus,
        item.createdDate ? getDateTimeFormate(item.createdDate) : "-",
        item.applyExpiry,
        item.updatedDate ? getDateTimeFormate(item.updatedDate) : "-",
        item.startDate ? getDateTimeFormate(item.startDate) : "-",
        item.validUntil ? getDateTimeFormate(item.validUntil) : "-",
        item.maxWinPercentage,
        item.bonusPercentage,
        item.depositCriteria,
        item.wagerCriteria,
        item.minDeposit,
        item.optIn,
        item.bonusDescription,
        "",
        // item?.bonusSports?.applicableSports?.map((item,ind) => ind == 0 ? item : `, ${item}`),
        "",
        item.oddsThreshold,
        item.unlockOddsThreshold,
        item.applicableType,
        item.unlockApplicableType,
        Array.isArray(item?.tags) &&
        item?.tags?.length > 0 &&
        item?.tags != null &&
        item.tags[0],
        item.brand == "starpunter" ? "StarPunter" : item.brand,
        item.numberOfLegs,
        item.unlockNumberOfLegs,
        item.minStake,
        item.bonusPayoutPercentage,
        item.referrerPercentage,
        item.refereePercentage,
        item.recReferrerPercentage,
        item.affiliateBtags[0]?.split(',')?.map(tag => tag.trim()),
        item.paymentMethods[0]?.split(',')?.map(tag => tag.trim()),
      ]);
      this.setState({ pdfBody: pdfBody });
      const csvDatas =
        Array.isArray(csvData) && csvData.length > 0
          ? [this.headers[0], ...csvData]
          : [this.headers[0], []];
      this.setState({ csvData: csvDatas });
      this.props.dispatch(setDocsData(csvDatas));
    }, 200);
  }

  setSelected(sport) {
    this.setState({ sportsSelected: sport });
    this.formDataToSliceSetter("sportsSelected", sport)
    if (Array.isArray(sport) && sport.length > 0) {
      const updatedErrors = this.state.errors.filter(
        (err) => err !== "sportsSelected"
      );

      this.setState({ errors: updatedErrors });
    }
  }

  onEditSubmit() {
    if (!this.state.editDateErr) {
      this.props.dispatch(setEditSaveDisable(true))
      const params = {
        ...this.state.editBonusData,
        bonusId: this.state.manageIDs.id,
        validUntil: convertEndDatePickerTimeToMySQLTime(this.state.validDate)
      }
      this.props.dispatch(updateBonusStatus(params));
      setTimeout(() => {
        this.callApi(
          this.props.paginationFirstValue,
          this.props.paginationSecondValue,
          this.state.payloadData
        );
      }, 500);
      this.handleEditClose()
    }
  }


  handleActiveDeactive(e, date) {
    const id = e.target.id;
    this.setState({ bonusId: id });
    let status;
    this.setState({ statusValue: e.target.value });
    switch (e.target.value) {
      case `activate${id}`:
        status = "ACTIVE";
        break;
      case `inactivate${id}`:
        status = "IN_ACTIVE";
        break;
      case `expired${id}`:
        status = "EXPIRED";
        break;
      default:
        status = "ACTIVE";
    }
    const params = {
      // bonusId: id,
      bonusStatus: status,
    };
    this.setState({ editBonusData: params })
    // this.props.dispatch(updateBonusStatus(params));
    // dispatch(updateUser(id, status));
    // setTimeout(() => {
    //   // this.props.dispatch(getBonusummery(500, 0));
    //   this.callApi(
    //     this.props.paginationFirstValue,
    //     this.props.paginationSecondValue,
    //     this.state.payloadData
    //   );
    // }, 500);
  }
  handleValidDate(date) {
    this.setState({ validDate: date })
    const { validDate, editValidUntil } = this.state
    if (extractDate(editValidUntil) > extractDate(date)) {
      this.setState({ editDateErr: true })
    } else {
      this.setState({ editDateErr: false })
    }
  }
  handleEditClose() {
    this.setState({ openEditBox: false, editDateErr: false })
  }
  handleEditOpen(item) {
    const startDate = new Date(item.startDate)
    this.setState({ openEditBox: true, editValidUntil: startDate })
    const newManageIDsState = {
      id: item.id,
      bonusCode: item.bonusCode,
      bonusStatus: item.bonusStatus,
    };
    this.props.dispatch(setEditSaveDisable(false))
    this.setState({
      manageIDs: newManageIDsState
    })
  }
  emptyStatehandler(exceptions) {
    this.props.dispatch(setFormData({}));
    const checkExist = (key) => exceptions && Array.isArray(exceptions) && exceptions.length > 0 ? exceptions.includes(key) : false;
    this.setState({
      applicableSports: "",
      applyExpiry: "",
      lastName: "",
      bonusPercentage: "",
      bonusAliases: "",
      bonusCode: "",
      bonusDescription: "",
      depositCriteria: "",
      ...(!checkExist("option") && { option: "" }),
      minDeposit: "",
      maxBonus: "",
      minBonus: "",
      startDate: "",
      tags: "",
      wagerBonus: "",
      wagerCriteria: 20,
      settlementType: "",
      validUntil: "",
      instantBonus: "",
      ...(!checkExist("bonusType") && { bonusType: "" }),
      oddsThreshold: 1.7,
      freebetStatus: "",
      // sportsSelected: [],
      hideFields: true,
      hidefieldsDeposit: true,
      freebetAmount: "",
      bonusName: "",
      errors: [],
      result: "",
      bonusAmount: "",
      oddsThresh: 1.2,
      minStake: 100,
      numberOfLegs: 10,
      bonusPayout: "",
      Referrer: "",
      Referee: "",
      Recurring: "",
      channel: "",
      releaseCriteria: "",
      minStakeRef: "",
      minDepositRef: "",
      bonusAmountRef: "",
      maxwinError: false,
      disableBtn: false,
      searchData: "",
      maxWinPercent: 100,
      maxWinAmount: 0,
      depoReleaseCriteria: "",
      depoUnlockedBonus: 0,
      depoLockedBonus: 100,
      rolloverTime: 5,
      unlockApplicableType: '',
      unlockNumberOfLegs: 4,
      unlockOddsThreshold: 1.5,
      unlockSportErr: '',
      unlocksportsSelected: [],
      unlockApplicableSportsIds: [],
      rollNumberOfLegs: 4,
      rollOddsThreshold: 1.5,
      rollOverApplicableType: '',
      rollOverSportId: [],
      rollOversportsSelected: [],
      rollOverSportErr: '',
      rollAppTypeErr: '',
      wagerAppTypeErr: '',
      allowTagsClearAll: true,
      denyTagsClearAll: true,
      sportId: [],
      providerName: [],
      shouldProviderFalse: false,
      providerErr: false,
      releaseProviders: [],
      releaseProvidersReset: false,
      gameName: [],
      gameNameReset: false,
      gameNameErr: false,
      releaseGameName: [],
      releaseGameNameReset: false,
      releaseProviderErr: false,
      shouldRollFalse: false,
      rollGameNameFalse: false,
      rollOverProviderReset: false,
      rollOverGameNameReset: false,
      rollOverProvider: [],
      rollOverGameName: [],
      bonusAppFor: '',
      bettingType: '',
      cashbackFrequency: '',
      cashbackType: '',
      isChecked: false,
      cashbackMinStake: '',
      cashbackMinOdds: '',
      cashbackNoLegs: '',
      cashbackPercentage: '',
      maxCashbackBonus: '',
      clearCashBackBetType: true,
      resetAffiliateNames: true,
      isPaymentMethodReset: true,
    });
    this.props.dispatch(setBonusCode(""));
    this.props.dispatch(setCountries([]));
    this.props.dispatch(setLeagues([]));
    this.props.dispatch(setEvents([]));

    this.props.dispatch(setIsLeagueLoading(true))
    this.props.dispatch(setIsCountriesLoading(true))
    this.props.dispatch(setIsEventLoading(true))


    this.props.dispatch(setCountryInBonus([]));
    this.props.dispatch(setLeaguesInBonus([]));
    this.props.dispatch(setEventBySportCountryLeague([]));
  }
  fetchAll() {
    const data = {
      pageNumber: 0,
      itemsPerPage: 25,
    };
    this.props.dispatch(fetchBonusList(data));
  }
  checkCountryDetails = (selection) => {
    return selection.length > 0 && selection.every(item => item.countryId && item.countryName);
  };
  depoFunction() {
    let countryDetailsPresent = this.checkCountryDetails(this.state.bonusSportSelection);
    const countryObj = this.props.countryInBonusPopup.countries?.find((c) => c.countryId === this.props.countries[0]);
    const leagueObj = this.props.leaguesInBonus.leagues?.find((league) => league.leagueId === this.props.leagues[0]);
    const eventObj = this.props.eventBySportCountryLeague?.events?.filter((event) =>
      this.props.events.includes(event.eventName)
    );
    const sportName = this.props.sportsList?.find(sport => sport.value === this.state.sportId[0]);
    let data;

    if (eventObj?.length > 0) {
      data = eventObj?.map(event => ({
        countryId: parseInt(countryObj?.countryId, 10),
        countryName: countryObj?.countryName,
        eventId: parseInt(event?.eventId, 10),
        eventName: event?.eventName,
        leagueId: parseInt(leagueObj?.leagueId, 10),
        leagueName: leagueObj?.leagueName,
        sportId: parseInt(this.state.sportId, 10),
        sportName: sportName?.label
      }));
    } else {
      data = [{
        countryId: parseInt(countryObj?.countryId, 10),
        countryName: countryObj?.countryName,
        eventId: 0,
        eventName: "",
        leagueId: parseInt(leagueObj?.leagueId, 10),
        leagueName: leagueObj?.leagueName,
        sportId: parseInt(this.state.sportId, 10),
        sportName: sportName?.label
      }];
    }

    let filteredData = data?.map(data =>
      Object.keys(data).reduce((acc, key) => {
        if (data[key] || (key === 'eventId' && data[key] !== 0)) {
          acc[key] = data[key];
        }
        return acc;
      }, {})
    );
    this.setState({ bonusFieldSelection: data })
    const rolloverCriteria = this.state.depoReleaseCriteria !== "ROLL_OVER_CRITERIA"
    const isRollOverCriteria = (this.state.bonusType === "GOODWILL" || this.state.bonusType === "DEPOSIT" || this.state.bonusType === "COUPONCODE") && this.state.depoReleaseCriteria === "ROLL_OVER_CRITERIA";
    const depositNoOfLegs = this.state.bonusType === "DEPOSIT" && (this.state.applicableSports === "CASINO" || this.state.applicableSports === "NE_GAMES")
    const goodWillNoOfLegs = this.state.bonusType === "GOODWILL" && (this.state.applicableSports === "CASINO" || this.state.applicableSports === "NE_GAMES")
    const couponCodeNoofLegs = this.state.bonusType === "COUPONCODE" && (this.state.applicableSports === "CASINO" || this.state.applicableSports === "NE_GAMES")
    const wagerDepositMinStake = this.state.depoReleaseCriteria === 'WAGER_CRITERIA' && this.state.bonusType === "DEPOSIT" && (this.state.applicableSports === "CASINO" || this.state.applicableSports === "NE_GAMES")
    const autoRegisterMinStake = (this.state.bonusType === "REGISTER" || this.state.bonusType === "REFERRAL") && (this.state.applicableSports === "CASINO" || this.state.applicableSports === "NE_GAMES")
    const BonusUsgaeNoOfLegs = this.props.events?.length >= 1 || this.state.isEventSelected
    let payload = {
      bonusType: this.state.bonusType,
      bonusCode: this.state.bonusType === "COUPONCODE" ? this.props.bonusCode : this.state.bonusName,
      maxBonus:
        this.state.bonusType === "FREE_BET" || this.state.bonusType === "GOODWILL"
          ? this.state.bonusAmount
          : this.state.bonusType === "REFERRAL"
            ? this.state.bonusAmountRef
            : this.state.maxBonus,
      minDeposit:
        this.state.bonusType === "REFERRAL"
          ? this.state.minDepositRef
          : this.state.minBonus,

      bonusPercentage:
        this.state.bonusPercentage, //deposit release %
      bonusDescription: this.state.bonusDescription,
      applyExpiry: this.state.applyExpiry,
      ...(this.state.applicableSports !== "CASINO" && {
        oddsThreshold:
          this.state.bonusType === "ONECUT" || this.state.bonusType === "TWOCUT"
            ? this.state.oddsThresh
            : rolloverCriteria ? this.state.oddsThreshold : '',
      }),
      startDate: convertStartDatePickerTimeToMySQLTime(
        this.state.startDate,
        "bonus"
      ),
      validUntil: convertEndDatePickerTimeToMySQLTime(this.state.validUntil),
      freebetStatus: this.state.freebetStatus,
      optIn: this.state.option,
      applicableType: this.state.applicableSports,
      tags: this.state.tags !== "" ? [this.state.tags] : [],
      instantBonus:
        this.state.depoReleaseCriteria === "ROLL_OVER_CRITERIA" ? this.state.depoUnlockedBonus :
          this.state.bonusType !== "FREE_BET" ? this.state.instantBonus : 100,
      wagerBonus: this.state.depoReleaseCriteria === "ROLL_OVER_CRITERIA" ? this.state.depoLockedBonus
        : this.state.bonusType !== "FREE_BET" ? this.state.wagerBonus : 0,//Locked %
      depositCriteria: this.state.depositCriteria,
      wagerCriteria:
        this.state.bonusType === "REFERRAL"
          ? this.state.releaseCriteria
          : this.state.wagerCriteria,
      minStake: (wagerDepositMinStake || autoRegisterMinStake) ? "" : (rolloverCriteria ? (this.state.bonusType === "REFERRAL" || this.state.bonusType === "GOODWILL" ? this.state.minStakeRef : this.state.minStake) : ''),
      numberOfLegs: (depositNoOfLegs || autoRegisterMinStake || goodWillNoOfLegs || couponCodeNoofLegs || BonusUsgaeNoOfLegs ? "" : (this.state.depoReleaseCriteria !== "ROLL_OVER_CRITERIA" ? this.state.numberOfLegs : '')),
      bonusPayoutPercentage: this.state.bonusPayout,
      recReferrerPercentage: this.state.Recurring,
      refereePercentage: this.state.Referee,
      referrerPercentage: this.state.Referrer,
      channel: this.state.channel,
      ...(this.state.bonusType === "REFERRAL" && { maxTotalBonus: this.state.maxBonus }),
      bonusStatus: "ACTIVE",
      maxWinPercentage: rolloverCriteria ? (this.state.maxWinPercent === "" ? 100 : this.state.maxWinPercent) : '',
      maxWinAmount: rolloverCriteria ? (this.state.maxWinAmount === "" ? 0 : this.state.maxWinAmount) : '',
      rollOverMultiplier: this.state.depoReleaseCriteria === "WAGER_CRITERIA" ? 0 : this.state.rolloverTime,
      bonusCriteria: this.state.depoReleaseCriteria === "ROLL_OVER_CRITERIA" ? this.state.depoReleaseCriteria : "WAGER_CRITERIA",
      unlockApplicableType: this.state.depoReleaseCriteria === "ROLL_OVER_CRITERIA" ? this.state.rollOverApplicableType : this.state.unlockApplicableType,
      unlockNumberOfLegs: ((this.state.applicableSports === "CASINO" || this.state.applicableSports === "NE_GAMES") ? "" : (this.state.depoReleaseCriteria === "ROLL_OVER_CRITERIA" ? this.state.rollNumberOfLegs : this.state.unlockNumberOfLegs)),
      unlockOddsThreshold: ((this.state.applicableSports === "CASINO" || this.state.applicableSports === "NE_GAMES") ? "" : (this.state.depoReleaseCriteria === "ROLL_OVER_CRITERIA" ? this.state.rollOddsThreshold : this.state.unlockOddsThreshold)),
      unlockApplicableSports: this.state.unlockApplicableType === "SPORTS" || this.state.unlockApplicableType === "ALL" ? this.state.unlockApplicableSportsIds : this.state.rollOverApplicableType === "SPORTS" ||
        this.state.rollOverApplicableType === "ALL" && this.state.depoReleaseCriteria === "ROLL_OVER_CRITERIA" ? this.state.rollOverSportId : [],
      allowTags: this.state.allowTags,
      denyTags: this.state.denyTags,
      providerName: this.state.providerName,
      gameName: this.state.gameName,
      unlockProviderName: isRollOverCriteria ? this.state.rollOverProvider : this.state.releaseProviders,
      unlockGameName: isRollOverCriteria ? this.state.rollOverGameName : this.state.releaseGameName,
      cashBackBetStatus: this.state.bonusAppFor,
      cashBackCriteria: this.state.bettingType,
      cashBackFrequency: this.state.cashbackFrequency,
      cashBackType: this.state.cashbackType,
      cashBackBetType: this.state.cashbackBetType,
      minCashBackOdds: this.state.cashbackMinOdds,
      minCashBackLegs: this.state.cashbackNoLegs,
      maxCashBackAmount: this.state.maxCashbackBonus,
      cashBackPercentage: this.state.cashbackPercentage,
      minStakeAmount: this.state.cashbackMinStake,
      addToWithdrawableAmount: this.state.isChecked,
      bonusSportSelection: (
        (this.props.onlySportsSelected || ((this.state.sportId.length >= 1 && this.props.countries.length === 0) && (!this.props.onlySportsSelected && !countryDetailsPresent))) ? "" : (this.state.sportId.length > 1 ? this.state.bonusSportSelection : filteredData)),
      ...(this.state.applicableSports !== "CASINO" && this.state.applicableSports !== "NE_GAMES" && {
        applicableSports: this.state.applicableSports === "ALL" ? this.props.defaultSports : ((this.props.onlySportsSelected || countryDetailsPresent) ? this.props.sportsSelectedInDialog : this.state.sportId)
      }),
      isCutBonusWithdrawable: ((this.state.bonusType === "ONECUT" || this.state.bonusType === "TWOCUT") ? this.state.isToggled : null),
      affliateBtags: this.state.affilateNames,
      paymentMethods: this.state.paymentMethod,
    };
    let err = [];
    if (this.state.bonusType !== "") {
      const filedData =
        this.state.option === "AUTO" && this.state.bonusType === "DEPOSIT"
          ? autoDeposit
          : this.state.option === "AUTO" && this.state.bonusType === "REGISTER"
            ? autoRegister
            : this.state.option === "DIRECT" && this.state.bonusType === "GOODWILL"
              ? goodWillBonus :
              this.state.option === "MANUAL" &&
                this.state.bonusType === "FREE_BET"
                ? freebetFields
                : (this.state.option === "AUTO" &&
                  this.state.bonusType === "ONECUT") ||
                  this.state.bonusType === "TWOCUT"
                  ? oneTwoCut
                  : this.state.option === "AUTO" && this.state.bonusType === "REFERRAL"
                    ? referalField
                    : nonFreebetFields;
      filedData.map((item) => {
        item != "bonusCode" && this.state[item].length === 0 && err.push(item);
        if (item == "bonusCode" && this.props.bonusCode === "") {
          err.push(item);
        }
      });
      if (this.state.bonusType === "DEPOSIT") {
        err = err.filter((err) => err !== "sportsSelected");
      }
      this.setState({ errors: err });
      if (
        this.state.bonusType === "REGISTER" ||
        this.state.bonusType === "DEPOSIT"
      ) {
        err = err.filter(
          (err) => !err.includes(["minBonus", "bonusPercentage"])
        );
      }
    } else {
      this.setState({ errors: ["bonusType"] });
      err.push("bonusType");
    }

    if (this.state.bonusType === "REGISTER") {
      this.setState({ rollAppTypeErr: false })
    }

    if (this.state.bonusType === "REGISTER" && this.state.releaseProviders.length > 0) {
      err = err.filter((err) => err !== "rollOverProvider" && err !== "rollOverGameName");
      this.setState({ errors: err })
    }

    if (this.state.applicableSports === "CASINO" || this.state.unlockApplicableType === 'CASINO' || this.state.rollOverApplicableType === 'CASINO') {
      err = err.filter((err) => !err.includes(["oddsThreshold", "unlockOddsThreshold", 'rollOddsThreshold']));
    }

    if (this.state.bonusType !== "") {
      const nonMandate = ["numberOfLegs", "oddsThresh", "oddsThreshold", "maxWinPercent", "maxWinAmount"]
      err = err.filter((x) => !nonMandate.includes(x));
      this.setState({ errors: err });
    }
    const rollover = this.state.depoReleaseCriteria === "ROLL_OVER_CRITERIA"
    if (rollover) {
      const nonMandate = ["instantBonus", "wagerBonus", "wagerCriteria", "unlockApplicableType", "minStakeRef", 'releaseProviders', 'releaseGameName']
      err = err.filter((x) => !nonMandate.includes(x));
      this.setState({ errors: err });
    }
    const wagerCriteria = this.state.depoReleaseCriteria === "WAGER_CRITERIA"
    if (wagerCriteria) {
      const nonMandate = ["rolloverTime", "rollOverApplicableType", 'rollOverGameName', 'rollOverProvider']
      err = err.filter((x) => !nonMandate.includes(x));
      this.setState({ errors: err });
    }
    const exceptions = this.state.applicableSports === "ALL" || this.state.applicableSports === "SPORTS"
    const releaseExceptions = this.state.unlockApplicableType === "ALL" || this.state.unlockApplicableType === "SPORTS"
    const rollOverExceptions = this.state.rollOverApplicableType === "ALL" || this.state.rollOverApplicableType === "SPORTS"

    if (exceptions) {
      const nonMandate = ['gameName', 'providerName']
      err = err.filter((x) => !nonMandate.includes(x));
      this.setState({ errors: err });
    }
    if (releaseExceptions) {
      const nonMandate = ['rollOverGameName', 'rollOverProvider']
      err = err.filter((x) => !nonMandate.includes(x));
      this.setState({ errors: err });
    }
    if (this.state.sportId?.length >= 1) {
      err = err.filter((err) => err !== "sportsSelected");
      this.setState({ errors: err });
    }
    if ((this.state.applicableSports === "CASINO" || this.state.applicableSports === "NE_GAMES") && this.state.bonusType === "COUPONCODE") {
      const nonMandate = ['minBonus', 'bonusPercentage']
      err = err.filter((x) => !nonMandate.includes(x));
      this.setState({ errors: err });
    }
    if (this.state.bonusType === "ONECUT" || this.state.bonusType === "TWOCUT" || this.state.bonusType === "FREEBET" || this.state.bonusType === "REFERRAL") {
      err = err.filter((x) => !['rollOverGameName', 'rollOverProvider', 'releaseProviders', 'releaseGameName'].includes(x));
      this.setState({ errors: err });
    }
    if (this.state.rollOverApplicableType === "ALL" || this.state.unlockApplicableType === "ALL" || this.state.rollOverApplicableType === "SPORTS" || this.state.unlockApplicableType === "SPORTS") {
      err = err.filter((x) => !['rollOverGameName', 'rollOverProvider', 'releaseProviders', 'releaseGameName'].includes(x));
      this.setState({ errors: err });
    }
    if (this.state.bonusType === "CASHBACK" && this.state.bettingType === "PER_BET") {
      err = err.filter((x) => !["cashbackFrequency"].includes(x));
      this.setState({ errors: err });
    }
    if (this.state.bonusType === "COUPONCODE" && this.state.tags === "register_dir") {
      err = err.filter((x) => !["minBonus", "bonusPercentage"].includes(x));
      this.setState({ errors: err });
    }
    const isRealCash = this.state.cashbackType === "REAL_CASH"
    const isCashback = this.state.bonusType === "CASHBACK"
    const isCasinoNeGame = this.state.applicableSports === "CASINO" || this.state.applicableSports === "NE_GAMES"
    const isGolbalSports = this.state.applicableSports === "ALL" || this.state.applicableSports === "SPORTS"
    if (isCashback) {
      if (isGolbalSports) {
        err = err.filter((x) => !['providerName', 'gameName', 'releaseProviders', 'releaseGameName', 'rollOverProvider', 'rollOverGameName', 'maxBonus'].includes(x));
        this.setState({ errors: err });
      }
      if (isGolbalSports && isRealCash) {
        err = err.filter((x) => !['providerName', 'gameName', 'depoReleaseCriteria', 'releaseProviders', 'releaseGameName', 'rollOverProvider', 'rollOverGameName', 'maxBonus'].includes(x));
        this.setState({ errors: err });
      }
      if (isCasinoNeGame && isRealCash) {
        err = err.filter((x) => !['depoReleaseCriteria', 'releaseProviders', 'releaseGameName', 'rollOverProvider', 'rollOverGameName', 'maxBonus'].includes(x));
        this.setState({ errors: err });
      }
      if (wagerCriteria) {
        err = err.filter((x) => !['maxBonus'])
        this.setState({ errors: err });
      }
      if (rollover) {
        err = err.filter((x) => !['releaseProviders', 'releaseGameName', 'maxBonus'])
        this.setState({ errors: err });
      }
    }



    this.props.dispatch(setCreateBonusErrors(err));
    const sportId = this.state.sportId;
    const unlockSport = this.state.unlockApplicableSportsIds;
    const maxWinPercent = this.state.maxWinPercent;
    const rollOverSport = this.state.rollOverSportId
    const wagerAppType = this.state.unlockApplicableType
    const rollAppType = this.state.rollOverApplicableType

    this.setState({ sportsErr: sportId.length === 0 });
    this.setState({ unlockSportErr: unlockSport.length === 0 });
    this.setState({ rollOverSportErr: rollOverSport.length === 0 });
    this.setState({ maxwinError: maxWinPercent > 100 });
    this.setState({
      wagerAppTypeErr: wagerAppType === '',
      rollAppTypeErr: rollAppType === ''
    })


    const sportErr = sportId.length === 0 && unlockSport.length === 0 && rollOverSport.length === 0
    if (
      err.length === 0 &&
      !this.state.sportsErr &&
      !this.state.unlockSportErr &&
      !this.state.maxwinError
      && !sportErr
      && !this.state.rollOverSportErr
      && !this.state.wagerAppTypeErr
      && !this.state.rollAppTypeErr
    ) {
      this.setState({ disableBtn: true });
      const obj = cleanObject(payload);
      this.props.dispatch(getBonus(obj, true));
      this.setState({ unlockSportErr: false });
      // this.emptyStatehandler();

      // setTimeout(() => {
      //   this.fetchAll();
      // }, 500);
    }
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.state.option !== "") {
      this.depoFunction();
    } else {
      this.setState({ errors: ["option"] });
      // this.formDataToSliceSetter("errors", ["option"]);
      this.props.dispatch(setCreateBonusErrors(["option"]));
    }
  }
  resetBonusStat = () => {
    this.setState({ filterBonusStatus: "" });
  }

  filterTableData(event) {

    const { value, name } = event.target;
    let filterPayload = {};

    switch (name) {
      case "filterBonusType":
        this.setState({ filterBonusStatus: "", filterAllocationType: "" });
        filterPayload = {
          bonusType: value,
          statusType: (value ? "" : this.state.filterBonusStatus),
          allocationType: (value ? "" : this.state.filterAllocationType),
        };
        this.callApi(
          this.props.paginationFirstValue,
          this.props.paginationSecondValue,
          filterPayload
        );
        break;

      case "filterBonusStatus":
        filterPayload = {
          bonusType: this.state.filterBonusType,
          statusType: value,
          allocationType: this.state.filterAllocationType,
        };
        this.callApi(
          this.props.paginationFirstValue,
          this.props.paginationSecondValue,
          filterPayload
        );
        break;

      case "filterAllocationType":
        filterPayload = {
          bonusType: this.state.filterBonusType,
          statusType: this.state.filterBonusStatus,
          allocationType: value,
        };
        this.callApi(
          this.props.paginationFirstValue,
          this.props.paginationSecondValue,
          filterPayload
        );

      default:
        break;
    }
    this.setState({ payloadData: filterPayload });
  }

  componentDidMount() {
    this.stateSetter();
    const dataExist = Object.keys(this.props?.bonusData).length > 0;
    if (!dataExist) {
      this.fetchAll();
      this.props.dispatch(fetchSportsList());
      this.props.dispatch(getProviderNamesDropdown());
      this.setInputs("", "");
    } else {
      this.setState({
        csvData: this.props.docsData,
      });
    }
    this.checkKeyExist("option");
    this.checkKeyExist("bonusType");
    this.setState({ errors: this.props.createBonusErrors });
    this.props.dispatch(getAffiliateNames())
    this.props.dispatch(getPaymentMethod())
  }
  checkKeyExist(key) {
    const Keys = Object.keys(this.props.formData);
    if (Keys && Keys.length > 0) {
      const exist = Keys.find((v) => v === key);
      if (exist) {
        const value = this.props?.formData[key];
        this.setInputs(key, value);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isBonusTableVisible !== this.props.isBonusTableVisible) {
      if (this.props.isBonusTableVisible) {
        this.fetchAll();
      }
    }
    if (prevProps.bonusData !== this.props.bonusData) {
      this.updateTableToDocs();
    }
    if (prevProps.formData !== this.props.formData) {
      if (!this.props.isBonusCreated) {
        this.stateSetter();
      } else {
        this.props.dispatch(setIsBonusCreated(false));
      }
    }
    // if (prevProps.defaultSports !== this.props.defaultSports) {
    //   // this.setSelected();
    //   this.setIds(this.props.defaultSports);
    // }
    if (prevProps.rcDefaultSports !== this.props.rcDefaultSports) {
      this.setUnlockSportId(this.props.rcDefaultSports);
    }
    if (prevProps.rollOverDefaultSports !== this.props.rollOverDefaultSports) {
      this.setRollOverSportId(this.props.rcDefaultSports);
    }
    if (prevProps.disableBtn !== this.props.disableBtn) {
      if (this.props.disableBtn) {
        setTimeout(() => {
          this.props.dispatch(setDisableBtn(false));
          this.setState({ disableBtn: false })
        }, 3000)
      }
    }
  }
  stateSetter() {
    const isFormDataExist = Object.keys(this.props.formData).length;
    if (isFormDataExist) {
      Object.keys(this.props.formData).map((key) =>
        this.setState({ [key]: this.props.formData[key] })
      );
    }
    const localState = {};
    if (isFormDataExist) {
      Object.keys(this.props.formData).map(
        (key) => (localState[key] = this.props.formData[key])
      );
    }
  }
  formDataToSliceSetter(key, val, multiEntries) {
    const newEntry = { ...this.props.formData, [key]: val };
    const newPayload = multiEntries
      ? { ...this.props.formData, ...multiEntries }
      : newEntry;
    this.props.dispatch(setFormData(newPayload));
  }

  setClear() {
    this.setState({ clearAll: false });
  }
  setIds(value, componentName) {
    if (componentName === "createBonus") {
      if (value?.length > 1) {
        this.props.dispatch(setOpenDialog(true));
      } else {
        this.props.dispatch(setOpenDialog(false));
      }
    }
    this.setInputs("events", []);
    this.setState({ sportId: value });
    if (!value) {
      this.props.dispatch(setIsCountriesLoading(true))
      this.props.dispatch(setIsLeagueLoading(true))
      this.props.dispatch(setIsEventLoading(true))
      this.props.dispatch(setLeaguesInBonus([]))
      this.props.dispatch(setEventBySportCountryLeague([]))
    }

    if (value.length === 1) {
      this.props.dispatch(getAllCountriesBySport(value));
    } else {
      this.props.dispatch(setIsCountriesLoading(true))
      this.props.dispatch(setIsLeagueLoading(true))
      this.props.dispatch(setIsEventLoading(true))
      this.props.dispatch(setCountries([]))
      this.props.dispatch(setLeagues([]))
      this.props.dispatch(setEvents([]))

    }
    value === "" ? this.setState({ sportsErr: true }) : this.setState({ sportsErr: false })
    this.formDataToSliceSetter("sportId", value);
    if (Array.isArray(value) && value.length > 0) {
      const updatedErrors = this.state.errors.filter(
        (err) => err !== "sportsSelected"
      );
      this.setState({ errors: updatedErrors });
    }
  }
  setInputs(name, value) {
    const index = (name) =>
      inputFieldData.findIndex((item) => item.name === name);
    const updateInd = (name) =>
      bonusCreationData.findIndex((item) => item.name === name);
    const allocationInd = (name) =>
      bonusAllocationIpData.findIndex((item) => item.name === name);
    const usageInd = (name) =>
      bonusUsageData.findIndex((item) => item.name === name);

    const opt = name === "option" ? value : this.state.option;
    const disableNoOfLegs = name === "events" ? value : this.props.events;
    const disbleNoOfLegsPopUp = name === "eventsOpt" ? value : this.state.isEventSelected;
    const optCondition = opt === "AUTO" ? true : false;
    // this.formDataToSliceSetter(name, value);

    const bonusType = name === "bonusType" ? value : this.state.bonusType;
    const tagType = name === "tags" ? value : this.state.tags;
    const applicableType =
      name === "applicableSports" ? value : this.state.applicableSports;
    const isDeposit = bonusType === "DEPOSIT" ? true : false;
    const isRegister = bonusType === "REGISTER" ? true : false;
    const isFreeBet = bonusType === "FREE_BET" ? true : false;
    const isCouponCode = bonusType === "COUPONCODE" ? true : false;
    const isOneCut = bonusType === "ONECUT" ? true : false;
    const isTwoCut = bonusType === "TWOCUT" ? true : false;
    const isCasino = (applicableType === "CASINO" || applicableType === "NE_GAMES") ? true : false;
    const isReferral = bonusType === "REFERRAL" ? true : false;
    const isGoodwill = bonusType === "GOODWILL" ? true : false;
    const isCashback = bonusType === "CASHBACK" ? true : false;
    const isEmpty = bonusType === "" ? true : false;
    const signUpTag = tagType === "register_dir" ? true : false;
    const isNoOFLegsVisible = disableNoOfLegs.length >= 1 ? true : false;
    const isNoOFLegInPopUpsVisible = disbleNoOfLegsPopUp ? true : false;
    const noOfLegsinEvent = isNoOFLegsVisible || isNoOFLegInPopUpsVisible
    if (isEmpty) {
      const data = bonusCreationData.filter(
        (item, i) => ![
          updateInd("bonusName"),
        ].includes(i)
      )
      const allocationData = bonusAllocationIpData.filter(
        (item, i) => ![
          allocationInd("bonusAmountRef")
        ].includes(i)
      )
      const fieldsData = inputFieldData.filter(
        (item, i) => ![
          index("bonusCode")
        ].includes(i)
      )
      const bonusUsage = bonusUsageData.filter(
        (item, i) => ![
          usageInd("oddsThresh")
        ].includes(i)
      )

      this.setState({ bonusUsageFields: bonusUsage, hideBottom: true });
      this.setState({ bonusCreateData: data, hideBottom: true });
      this.setState({ bonusAllocationFields: allocationData, hideBottom: true });
      // this.setState({ fieldsData: fieldsData, hideBottom: true });
    }
    if (isDeposit) {
      const filterAllocation = bonusAllocationIpData.filter(
        (item, i) =>
          ![
            allocationInd("releaseCriteria"),
            allocationInd("minDepositRef"),
            allocationInd("bonusAmountRef"),
            allocationInd("cashbackMinStake"),
            allocationInd("cashbackMinOdds"),
            allocationInd("cashbackNoLegs"),
            allocationInd("cashbackPercentage"),
            allocationInd("maxCashbackBonus"),
          ].includes(i)
      );
      const filterUsage = bonusUsageData.filter(
        (item, i) => ![
          usageInd("oddsThresh"),
          usageInd("minStakeRef"),
          usageInd("minStake"),
          usageInd("bonusPayout"),
        ].includes(i)
      )
      this.setState({ bonusAllocationFields: filterAllocation, hideBottom: true });
      this.setState({ bonusUsageFields: filterUsage, hideBottom: true });
      this.setState({ fieldsData: [...inputFieldData], hideBottom: true });
    }
    if (isRegister) {
      const filterAllocation = bonusAllocationIpData.filter(
        (item, i) =>
          ![
            allocationInd("minBonus"),
            allocationInd("bonusAmountRef"),
            allocationInd("bonusAmount"),
            allocationInd("minDepositRef"),
            allocationInd("bonusPercentage"),
            allocationInd("depoReleaseCriteria"),
            allocationInd("releaseCriteria"),
            allocationInd("cashbackMinStake"),
            allocationInd("cashbackMinOdds"),
            allocationInd("cashbackNoLegs"),
            allocationInd("cashbackPercentage"),
            allocationInd("maxCashbackBonus"),
          ].includes(i)
      );
      const filterUsage = bonusUsageData.filter(
        (item, i) => ![
          usageInd("oddsThresh"),
          usageInd("minStakeRef"),
          usageInd("minStake"),
          usageInd("bonusPayout"),
        ].includes(i)
      )
      this.setState({ bonusUsageFields: filterUsage, hideBottom: true });
      this.setState({ bonusAllocationFields: filterAllocation, hideBottom: true });
    }
    if (isFreeBet) {
      const data = inputFieldData.filter(
        (item, i) =>
          ![
            index("tags"),
            index("bonusCode")
          ].includes(i)
      );
      this.setState({ fieldsData: data, hideBottom: true });
      const creationData = bonusCreationData.filter(
        (item, i) => [
          updateInd("bonusName"),
          updateInd("applyExpiry"),
        ].includes(i)
      )
      const filterAllocation = bonusAllocationIpData.filter(
        (item, i) =>
          ![
            allocationInd("minBonus"),
            allocationInd("bonusPercentage"),
            allocationInd("depoReleaseCriteria"),
            allocationInd("releaseCriteria"),
            allocationInd("bonusAmountRef"),
            allocationInd("minDepositRef"),
            allocationInd("maxBonus"),
            allocationInd("cashbackMinStake"),
            allocationInd("cashbackMinOdds"),
            allocationInd("cashbackNoLegs"),
            allocationInd("cashbackPercentage"),
            allocationInd("maxCashbackBonus"),
          ].includes(i)
      );
      const filterUsage = bonusUsageData.filter(
        (item, i) => ![
          usageInd("oddsThresh"),
          usageInd("minStakeRef"),
          usageInd("minStake"),
          usageInd("bonusPayout"),
          usageInd("maxBonus"),
        ].includes(i)
      )
      this.setState({ bonusCreateData: creationData, hideBottom: true });
      this.setState({ bonusAllocationFields: filterAllocation, hideBottom: true });
      this.setState({ bonusUsageFields: filterUsage, hideBottom: true });


    }
    if (isNoOFLegsVisible || isNoOFLegInPopUpsVisible) {
      const filterUsage = bonusUsageData.filter(
        (item, i) => ![
          usageInd("numberOfLegs"),
          usageInd("oddsThresh"),
          usageInd("minStake"),
          usageInd("minStakeRef"),
          usageInd("bonusPayout"),
        ].includes(i)
      )
      this.setState({ bonusUsageFields: filterUsage, hideBottom: true });
    }

    if (isCouponCode) {
      const getFilteredData = (data, indices) => {
        return data.filter((item, i) => !indices.includes(i));
      };

      const creationData = bonusCreationData.filter((item, i) => [updateInd("applyExpiry")].includes(i));

      const commonFilterIndices = [
        allocationInd("bonusAmount"),
        allocationInd("releaseCriteria"),
        allocationInd("bonusAmountRef"),
        allocationInd("minDepositRef"),
        allocationInd("cashbackMinStake"),
        allocationInd("cashbackMinOdds"),
        allocationInd("cashbackNoLegs"),
        allocationInd("cashbackPercentage"),
        allocationInd("maxCashbackBonus"),
      ];

      let filterAllocation = getFilteredData(bonusAllocationIpData, commonFilterIndices);

      let filterUsage = getFilteredData(bonusUsageData, [
        usageInd("oddsThresh"),
        usageInd("minStakeRef"),
        usageInd("minStake"),
        usageInd("bonusPayout"),
      ]);

      const includeFields = inputFieldData.filter((item, i) => [
        index("bonusType"),
        index("startDate"),
        index("validUntil"),
        index("applicableSports"),
        index("tags"),
        index("bonusCode"),
      ].includes(i));

      if (signUpTag) {
        const signUpFilterIndices = [
          ...commonFilterIndices,
          allocationInd("minBonus"),
          allocationInd("bonusPercentage"),
        ];
        filterAllocation = getFilteredData(bonusAllocationIpData, signUpFilterIndices);
      }
      const noOfLegsinEvent = isNoOFLegsVisible || isNoOFLegInPopUpsVisible;
      if (noOfLegsinEvent) {
        filterUsage = filterUsage.filter((item, i) => usageInd("numberOfLegs") !== i);
      }

      this.setState({
        bonusCreateData: creationData,
        bonusAllocationFields: filterAllocation,
        bonusUsageFields: filterUsage,
        fieldsData: includeFields,
        hideBottom: true,
      });
    }


    if (isOneCut || isTwoCut) {
      let filterUsage = bonusUsageData.filter(
        (item, i) =>
          ![
            usageInd("oddsThreshold"),
            usageInd("maxWinAmount"),
            usageInd("maxWinPercent"),
            usageInd("maxBonus"),
            usageInd("minStakeRef"),
          ].includes(i)
      );
      if (noOfLegsinEvent) {
        filterUsage = filterUsage.filter(
          (item, i) => usageInd("numberOfLegs") !== i
        );
      }
      this.setState({ bonusUsageFields: filterUsage, hideBottom: true });
    }

    if (isCasino && isDeposit) {
      const filterAllocation = bonusAllocationIpData.filter(
        (item, i) => ![
          allocationInd("releaseCriteria"),
          allocationInd("minDepositRef"),
          allocationInd("bonusAmountRef"),
          allocationInd("unlockNumberOfLegs"),
          allocationInd("unlockOddsThreshold"),
          allocationInd("cashbackMinStake"),
          allocationInd("cashbackMinOdds"),
          allocationInd("cashbackNoLegs"),
          allocationInd("cashbackPercentage"),
          allocationInd("maxCashbackBonus"),

        ].includes(i)
      )
      const filterUsage = bonusUsageData.filter(
        (item, i) => ![
          usageInd("oddsThreshold"),
          usageInd("oddsThresh"),
          usageInd("minStake"),
          usageInd("minStakeRef"),
          usageInd("bonusPayout"),
          usageInd("numberOfLegs"),
        ].includes(i)
      )
      this.setState({ bonusAllocationFields: filterAllocation, hideBottom: true });
      this.setState({ bonusUsageFields: filterUsage, hideBottom: true });
    }

    if (isCasino && isRegister) {
      const filterUsage = bonusUsageData.filter(
        (item, i) => ![
          usageInd("oddsThreshold"),
          usageInd("oddsThresh"),
          usageInd("minStake"),
          usageInd("minStakeRef"),
          usageInd("bonusPayout"),
          usageInd("numberOfLegs"),
        ].includes(i)
      )
      this.setState({ bonusUsageFields: filterUsage, hideBottom: true });
    }

    if (isCasino && isCouponCode) {
      const filterUsage = bonusUsageData.filter(
        (item, i) => ![
          usageInd("oddsThreshold"),
          usageInd("oddsThresh"),
          usageInd("minStake"),
          usageInd("minStakeRef"),
          usageInd("bonusPayout"),
          usageInd("numberOfLegs"),
        ].includes(i)
      )
      this.setState({ bonusUsageFields: filterUsage, hideBottom: true });
    }

    if (isReferral) {
      const filterAllocation = bonusAllocationIpData.filter(
        (item, i) =>
          ![
            allocationInd("minBonus"),
            allocationInd("depoReleaseCriteria"),
            allocationInd("cashbackMinStake"),
            allocationInd("cashbackMinOdds"),
            allocationInd("cashbackNoLegs"),
            allocationInd("cashbackPercentage"),
            allocationInd("maxCashbackBonus"),
          ].includes(i)
      );
      let filterUsage = bonusUsageData.filter(
        (item, i) => ![
          usageInd("oddsThresh"),
          usageInd("minStake"),
          usageInd("bonusPayout"),
        ].includes(i)
      );
      if (noOfLegsinEvent) {
        filterUsage = filterUsage.filter(
          (item, i) => usageInd("numberOfLegs") !== i
        );
      }
      this.setState({ bonusAllocationFields: filterAllocation, hideBottom: true });
      this.setState({ bonusUsageFields: filterUsage, hideBottom: true });

    }
    if (isReferral && isCasino) {
      const filterUsage = bonusUsageData.filter(
        (item, i) => ![
          usageInd("oddsThresh"),
          usageInd("minStake"),
          usageInd("bonusPayout"),
          usageInd("oddsThreshold"),
          usageInd("numberOfLegs"),
        ].includes(i)
      )
      this.setState({ bonusUsageFields: filterUsage, hideBottom: true });
    }

    if (isGoodwill) {
      const creationData = bonusCreationData.filter(
        (item, i) => [
          updateInd("bonusName"),
          updateInd("applyExpiry"),
        ].includes(i)
      )
      const filterAllocation = bonusAllocationIpData.filter(
        (item, i) =>
          ![
            allocationInd("minBonus"),
            allocationInd("minDepositRef"),
            allocationInd("bonusPercentage"),
            allocationInd("releaseCriteria"),
            allocationInd("bonusAmountRef"),
            allocationInd("maxBonus"),
            allocationInd("cashbackMinStake"),
            allocationInd("cashbackMinOdds"),
            allocationInd("cashbackNoLegs"),
            allocationInd("cashbackPercentage"),
            allocationInd("maxCashbackBonus"),
          ].includes(i)
      );
      let filterUsage = bonusUsageData.filter(
        (item, i) => ![
          usageInd("oddsThresh"),
          usageInd("maxBonus"),
          usageInd("minStake"),
          usageInd("bonusPayout"),
        ].includes(i)
      )
      const fieldsData = inputFieldData.filter(
        (item, i) => ![
          index("bonusCode")
        ].includes(i)
      )
      if (noOfLegsinEvent) {
        filterUsage = filterUsage.filter(
          (item, i) => usageInd("numberOfLegs") !== i
        );
      }
      this.setState({ bonusUsageFields: filterUsage, hideBottom: true });
      this.setState({ bonusCreateData: creationData, hideBottom: true });
      this.setState({ bonusAllocationFields: filterAllocation, hideBottom: true });
      this.setState({ fieldsData: fieldsData, hideBottom: true });


    }
    if (isGoodwill && isCasino) {
      const filterUsage = bonusUsageData.filter(
        (item, i) => ![
          usageInd("oddsThresh"),
          usageInd("maxBonus"),
          usageInd("minStake"),
          usageInd("bonusPayout"),
          usageInd("oddsThreshold"),
          usageInd("numberOfLegs"),
        ].includes(i)
      )
      this.setState({ bonusUsageFields: filterUsage, hideBottom: true });
    }

    if (isCashback) {
      const filterAllocation = bonusAllocationIpData.filter(
        (item, i) =>
          ![
            allocationInd("minBonus"),
            allocationInd("minDepositRef"),
            allocationInd("bonusPercentage"),
            allocationInd("releaseCriteria"),
            allocationInd("bonusAmountRef"),
            allocationInd("maxBonus"),
            allocationInd("bonusAmount"),
            allocationInd("minDepositRef"),

          ].includes(i)
      );

      let filterUsage = bonusUsageData.filter(
        (item, i) => ![
          usageInd("oddsThresh"),
          usageInd("maxBonus"),
          usageInd("minStake"),
          usageInd("minStakeRef"),
          usageInd("bonusPayout"),
        ].includes(i)
      )
      if (noOfLegsinEvent) {
        filterUsage = filterUsage.filter(
          (item, i) => usageInd("numberOfLegs") !== i
        );
      }
      this.setState({ bonusAllocationFields: filterAllocation, hideBottom: true });
      this.setState({ bonusUsageFields: filterUsage, hideBottom: true });
    }
    if (isCashback && isCasino) {
      const filterUsage = bonusUsageData.filter(
        (item, i) => ![
          usageInd("oddsThresh"),
          usageInd("maxBonus"),
          usageInd("minStake"),
          usageInd("bonusPayout"),
          usageInd("oddsThreshold"),
          usageInd("numberOfLegs"),
          usageInd("minStakeRef"),
        ].includes(i)
      )
      this.setState({ bonusUsageFields: filterUsage, hideBottom: true });
    }

    if (isCashback && this.state.cashbackType === "REAL_CASH") {
      const filterAllocation = bonusAllocationIpData.filter(
        (item, i) =>
          ![
            allocationInd("depoReleaseCriteria"),
            allocationInd("minBonus"),
            allocationInd("bonusAmountRef"),
            allocationInd("minDepositRef"),
            allocationInd("bonusPercentage"),
            allocationInd("releaseCriteria"),
            allocationInd("maxBonus"),
          ].includes(i)
      );
      this.setState({ bonusAllocationFields: filterAllocation, hideBottom: true });

    }

  }

  // setValues(event) {
  //   const { name, value } = event.target;
  //   if (name === "rolloverTime" && +value === 0 && !!value) {
  //     return;
  //   }
  //   if (["unlockApplicableType", "applicableSports"].includes(name)) {
  //     if (value === "CASINO" || value === "ALL" || value === "NE_GAMES") {
  //       this.setState({ unlockSportErr: false })
  //     }
  //   }
  //   // if (name === "option") {
  //   //   const updatedData = this.state.fieldsData.map(item => {
  //   //     if (item.id === 789 && Array.isArray(item.options)) {
  //   //       return {
  //   //         ...item,
  //   //         options: []
  //   //       };
  //   //     }
  //   //     return item;
  //   //   });

  //   //   console.log("updatedData: ", updatedData);
  //   //   this.setState({ fieldsData: updatedData })
  //   // }
  //   if (name === "tags") {
  //     const payload = {
  //       bonusType: this.state.bonusType,
  //       tags: value,
  //     }
  //     const actualPayload = cleanObject(payload)
  //     this.props.dispatch(AllowTax(actualPayload))
  //   }
  //   //setting ONECUT && TWOCUT not included state values

  //   if (value === "ONECUT" || value === "TWOCUT") {
  //     this.setState({
  //       maxWinPercent: '',
  //       minStakeRef: '',
  //       minStake: '',
  //       rolloverTime: '',
  //       rollNumberOfLegs: '',
  //       unlockNumberOfLegs: '',
  //       rollOddsThreshold: '',
  //       unlockOddsThreshold: '',
  //       releaseCriteria: '',
  //       wagerCriteria: '',
  //       rollOverApplicableType: '',
  //       unlockApplicableType: ''
  //     })
  //   }

  //   //setting for FREE_BET not included state values
  //   if (value === "FREE_BET") {
  //     this.setState({
  //       wagerCriteria: '',
  //       rolloverTime: '',
  //       rollNumberOfLegs: '',
  //       unlockNumberOfLegs: '',
  //       rollOddsThreshold: '',
  //       unlockOddsThreshold: '',
  //       releaseCriteria: '',
  //       rollOverApplicableType: '',
  //       unlockApplicableType: ''
  //     })
  //   }

  //   if (value === "REFERRAL") {
  //     this.setState({
  //       wagerCriteria: '',
  //       rolloverTime: '',
  //       rollNumberOfLegs: '',
  //       unlockNumberOfLegs: '',
  //       rollOddsThreshold: '',
  //       unlockOddsThreshold: '',
  //       releaseCriteria: '',
  //       rollOverApplicableType: '',
  //       unlockApplicableType: '',
  //       wagerAppTypeErr: false,
  //       rollAppTypeErr: false,
  //       rollOverSportErr: false,
  //       unlockSportErr: false
  //     })
  //   }

  //   if (value === "REGISTER") {
  //     this.setState({
  //       rolloverTime: ''
  //     })
  //   }
  //   if (this.state.depoReleaseCriteria === "ROLL_OVER_CRITERIA" && (this.state.providerName || this.state.rollOverApplicableType === "SPORTS")) {
  //     this.setState({ wagerAppTypeErr: false, unlockSportErr: false })
  //   }
  //   if (this.state.depoReleaseCriteria === "WAGER_CRITERIA" && this.state.providerName) {
  //     this.setState({ rollAppTypeErr: false, unlockSportErr: false })
  //   }

  //   //setting values for Release Criteria
  //   if (["depositCriteria", "wagerCriteria"].includes(name)) {
  //     if (value <= 100) {
  //       this.setState({ [name]: value });
  //       this.formDataToSliceSetter(name, value);
  //     }
  //   }
  //   else {
  //     this.setState({ [name]: value });
  //     name !== "filterBonusStatus" && name !== "filterAllocationType" && this.formDataToSliceSetter(name, value);
  //     // if(name === "instantBonus"){ this.formDataToSliceSetter("wagerBonus", 100 - value)};
  //   }
  //   //setting values for Release Criteria
  //   if (value.length > 0) {
  //     const updatedErrors = this.state.errors.filter((err) =>
  //       name === "instantBonus"
  //         ? err !== name && err !== "wagerBonus"
  //         : err !== name
  //     );
  //     this.setState({ errors: updatedErrors });
  //   }

  //   if (name === "instantBonus") {
  //     this.setState({
  //       wagerBonus: 100 - value,
  //     });
  //     this.formDataToSliceSetter("", "", {
  //       [name]: value,
  //       wagerBonus: 100 - value,
  //     });
  //     if (value === "") {
  //       this.setState({ wagerBonus: "" });
  //       this.formDataToSliceSetter("", "", { [name]: "", wagerBonus: "" });
  //     }
  //   }
  //   if (name === "bonusType") {
  //     if (name !== this.state.bonusType && errors.length > 0) {
  //       this.setState({ errors: [] });
  //     }
  //     if (value === "") {
  //       this.setState({ errors: ["bonusType"] });
  //     }
  //     this.setState({ numberOfLegs: value === "TWOCUT" ? 18 : value === "ONECUT" ? 10 : 4 })
  //   }

  //   if (name === "option") {
  //     this.setState({ errors: [], bonusType: "" });
  //     this.formDataToSliceSetter("bonusType", "");
  //   }
  //   if (name === "maxWinPercent" && value > 100) {
  //     this.setState({ maxwinError: true })
  //   } else {
  //     this.setState({ maxwinError: false })
  //   }
  //   if ((name === "unlockApplicableType") && value === '') {
  //     this.setState({ wagerAppTypeErr: true, rollAppTypeErr: false })
  //   } else {
  //     this.setState({ wagerAppTypeErr: false })
  //   }
  //   if (name === "rollOverApplicableType" && value === '') {
  //     this.setState({ rollAppTypeErr: true, wagerAppTypeErr: false })
  //   } else {
  //     this.setState({ rollAppTypeErr: false })
  //   }
  //   this.filterTableData(event);
  //   this.setInputs(name, value);
  //   const mainTypes = ["option", "bonusType"]
  //   const isBonusTypeChanged = mainTypes.includes(name);
  //   if (isBonusTypeChanged) {
  //     if (this.props.isBonusCreated) {
  //       this.emptyStatehandler(mainTypes);
  //       this.props.dispatch(setFormData({}));
  //     }
  //     this.props.dispatch(setCreateBonusErrors([]));
  //   }
  // }

  setValues = (event) => {
    const { name, value } = event.target;

    if (name === "rolloverTime" && +value === 0 && !!value) return;

    if (["unlockApplicableType", "applicableSports"].includes(name) &&
      ["CASINO", "ALL", "NE_GAMES"].includes(value)) {
      this.setState({ unlockSportErr: false });
    }

    if (name === "tags") {
      const payload = {
        bonusType: this.state.bonusType,
        tags: value,
      };
      const actualPayload = cleanObject(payload);
      this.props.dispatch(AllowTax(actualPayload));
    }

    if (["ONECUT", "TWOCUT"].includes(value)) {
      this.resetStateForCuts();
    }

    if (value === "FREE_BET") {
      this.resetStateForFreeBet();
    }

    if (value === "REFERRAL") {
      this.resetStateForReferral();
    }

    if (value === "REGISTER") {
      this.setState({ rolloverTime: '' });
    }

    if (this.state.depoReleaseCriteria === "ROLL_OVER_CRITERIA" && (this.state.providerName || this.state.rollOverApplicableType === "SPORTS")) {
      this.setState({ wagerAppTypeErr: false, unlockSportErr: false });
    }

    if (this.state.depoReleaseCriteria === "WAGER_CRITERIA" && this.state.providerName) {
      this.setState({ rollAppTypeErr: false, unlockSportErr: false });
    }

    if (["depositCriteria", "wagerCriteria"].includes(name) && value <= 100) {
      this.updateFormAndState(name, value);
    } else {
      this.updateFormAndState(name, value);
    }

    if (value.length > 0) {
      this.removeErrors(name);
    }

    if (name === "instantBonus") {
      this.handleInstantBonus(value);
    }

    if (name === "bonusType") {
      this.handleBonusType(value);
    }

    this.handleMaxWinPercentError(name, value);
    this.handleApplicableTypeErrors(name, value);
    this.filterTableData(event);
    this.setInputs(name, value);

    this.handleMainTypeChanges(name, ["option", "bonusType"]);
  };

  resetStateForCuts = () => {
    this.setState({
      maxWinPercent: '',
      minStakeRef: '',
      minStake: '',
      rolloverTime: '',
      rollNumberOfLegs: '',
      unlockNumberOfLegs: '',
      rollOddsThreshold: '',
      unlockOddsThreshold: '',
      releaseCriteria: '',
      wagerCriteria: '',
      rollOverApplicableType: '',
      unlockApplicableType: ''
    });
  };

  resetStateForFreeBet = () => {
    this.setState({
      wagerCriteria: '',
      rolloverTime: '',
      rollNumberOfLegs: '',
      unlockNumberOfLegs: '',
      rollOddsThreshold: '',
      unlockOddsThreshold: '',
      releaseCriteria: '',
      rollOverApplicableType: '',
      unlockApplicableType: ''
    });
  };

  resetStateForReferral = () => {
    this.setState({
      wagerCriteria: '',
      rolloverTime: '',
      rollNumberOfLegs: '',
      unlockNumberOfLegs: '',
      rollOddsThreshold: '',
      unlockOddsThreshold: '',
      releaseCriteria: '',
      rollOverApplicableType: '',
      unlockApplicableType: '',
      wagerAppTypeErr: false,
      rollAppTypeErr: false,
      rollOverSportErr: false,
      unlockSportErr: false
    });
  };

  updateFormAndState = (name, value) => {
    this.setState({ [name]: value });
    name !== "filterBonusStatus" && name !== "filterAllocationType" && this.formDataToSliceSetter(name, value);
  };

  removeErrors = (name) => {
    const updatedErrors = this.state.errors.filter(err =>
      name === "instantBonus" ? err !== name && err !== "wagerBonus" : err !== name
    );
    this.setState({ errors: updatedErrors });
  };

  handleInstantBonus = (value) => {
    this.setState({ wagerBonus: 100 - value });
    this.formDataToSliceSetter("", "", { instantBonus: value, wagerBonus: 100 - value });

    if (value === "") {
      this.setState({ wagerBonus: "" });
      this.formDataToSliceSetter("", "", { instantBonus: "", wagerBonus: "" });
    }
  };

  handleBonusType = (value) => {
    if (value === "") {
      this.setState({ errors: ["bonusType"] });
    }
    this.setState({ numberOfLegs: value === "TWOCUT" ? 18 : value === "ONECUT" ? 10 : 4 });
  };

  handleMaxWinPercentError = (name, value) => {
    if (name === "maxWinPercent" && value > 100) {
      this.setState({ maxwinError: true });
    } else {
      this.setState({ maxwinError: false });
    }
  };

  handleApplicableTypeErrors = (name, value) => {
    if (name === "unlockApplicableType" && value === '') {
      this.setState({ wagerAppTypeErr: true, rollAppTypeErr: false });
    } else if (name === "rollOverApplicableType" && value === '') {
      this.setState({ rollAppTypeErr: true, wagerAppTypeErr: false });
    } else {
      this.setState({ rollAppTypeErr: false, wagerAppTypeErr: false });
    }
  };

  handleMainTypeChanges = (name, mainTypes) => {
    if (mainTypes.includes(name)) {
      if (this.props.isBonusCreated) {
        this.emptyStatehandler(mainTypes);
        this.props.dispatch(setFormData({}));
      }
      this.props.dispatch(setCreateBonusErrors([]));
    }
  };

  setDates(value, name) {
    this.setState({ [name]: value });
    this.formDataToSliceSetter(name, value);
    if (value !== undefined) {
      const updatedErrors = this.state.errors.filter((err) => err !== name);
      this.setState({ errors: updatedErrors });
    } else {
      this.setState({ [name]: "" });
    }
  }

  setRandomCode() {
    const randomValue = generateCode(8);
    this.setState({ result: randomValue, bonusCode: randomValue });
  }


  openDialogBox() {
    this.props.dispatch(setIsDialogOpen(true))
  }

  async handleFilter(value) {
    let data = []
    if (value) {
      data = await this.props.bonusDataDuplicate?.data.filter((item) => item.bonusCode?.toLowerCase().replace(' ', '').includes(value.toLowerCase().replace(' ', '')))
      this.props.dispatch(setCreateBonus({ data, totalRecords: data.length }));
      if (data.length == 0) {
        this.openDialogBox()
      }
    } else {
      data = this.props.bonusDataDuplicate.data
    }
  }

  debounce(callback, delay) {
    let timer
    return (...args) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        callback(...args)
      }, delay)
    }
  }

  debounceLog = this.debounce((value) => this.handleFilter(value), 100)
  handleSearch(e) {

    const val = this.state.searchData
    if (val === "") {
      this.setState({ bonusCodeErr: true })
    }
    else {
      const bonus = {
        pageNumber: 0,
        itemsPerPage: 25,
        bonusType: this.state.filterBonusType,
        statusType: this.state.filterBonusStatus,
        allocationType: this.state.filterAllocationType,
        bonusCode: this.state.searchData,

      }
      this.props.dispatch(fetchBonusList(bonus));
      this.setState({ bonusCodeErr: false })
    }
  }

  handleInput(e) {
    const { name } = e.target
    this.setState({ searchData: e.target.value })
    if (name === "searchData") {
      this.setState({ bonusCodeErr: false })
    }
  }

  onFocus() {
    const data = {
      pageNumber: 0,
      itemsPerPage: this.props.bonusDataDuplicate.totalRecords,
    };
    this.props.dispatch(fetchBonusList(data));
  }


  onReject() {
    this.props.dispatch(setBonusAlert(false));
  }

  onAccept() {
    this.myRef.current.focus()
    this.props.dispatch(setBonusAlert(false));
  }

  componentWillUnmount() {
    this.props.dispatch(setIsBonusCreated(false));
  }

  setUnlockSportId(value) {
    this.setState({ unlockApplicableSportsIds: value });
    value === "" ? this.setState({ unlockSportErr: true }) : this.setState({ unlockSportErr: false })
    this.formDataToSliceSetter("unlockApplicableSportsIds", value);
  }

  setUnlockSportClear() {
    this.setState({ clearUnlockSports: false });
  }

  setUnlockSportSelected(sport) {
    this.setState({ unlocksportsSelected: sport });
    this.formDataToSliceSetter("unlocksportsSelected", sport)
    if (Array.isArray(sport) && sport.length > 0) {
      const updatedErrors = this.state.errors.filter(
        (err) => err !== "unlocksportsSelected"
      );
      this.setState({ errors: updatedErrors });
    }
    if (sport.length > 0) {
      this.setState({ unlockSportErr: false })
    } else {
      this.setState({ unlockSportErr: true })
    }
  }

  //ROLLOVER SPORTSELECTION OPTIONS

  setRollOverSportId(value) {
    this.setState({ rollOverSportId: value });
    value === "" ? this.setState({ rollOverSportErr: true }) : this.setState({ rollOverSportErr: false })
    this.formDataToSliceSetter("rollOverSportId", value);
  }
  setRollOverSportClear() {
    this.setState({ clearRollOverSports: false });

  }
  setRollOverSportSelected(sport) {
    this.setState({ rollOversportsSelected: sport });
    this.formDataToSliceSetter("rollOversportsSelected", sport)
    if (Array.isArray(sport) && sport.length > 0) {
      const updatedErrors = this.state.errors.filter(
        (err) => err !== "rollOversportsSelected"
      );
      this.setState({ errors: updatedErrors });
    }
    if (sport.length > 0) {
      this.setState({ rollOverSportErr: false })
    } else {
      this.setState({ rollOverSportErr: true })
    }
  }
  setSelectedAllowtTags(allow) {
    this.setState({ allowTags: allow, allowTagsClearAll: false });
    this.formDataToSliceSetter(null, null, {
      allowTags: allow,
      allowTagsClearAll: false
    })
  }
  setSelectedDenytTags(deny) {
    this.setState({ denyTags: deny, denyTagsClearAll: false });
    this.formDataToSliceSetter(null, null, {
      denyTags: deny,
      denyTagsClearAll: false
    })
  }

  handleSelectCasino(casino) {
    this.setState({ providerName: casino, shouldProviderFalse: false });

    this.formDataToSliceSetter("providerName", casino);
    const payload = {
      providerNames: casino
    }
    if (casino.length > 0) {
      this.props.dispatch(getProviderName(payload, "bonusCreation"));
      const errs = this.state.errors.filter(item => item !== "providerName");
      this.setState({ errors: errs })
    }

    const bonuses = ["REFERRAL", "ONECUT", "TWOCUT", "GOODWILL "];
    const applicableSports = ["CASINO", "NE_GAMES"];
    if (bonuses.includes(this.state.bonusType) && applicableSports.includes(this.state.applicableSports)) {
      this.setState({ wagerAppTypeErr: false, rollAppTypeErr: false, unlockSportErr: false, rollOverSportErr: false });
    }
  }

  handleGameName(game) {
    this.setState({ gameName: game, gameNameReset: false });
    this.formDataToSliceSetter("gameName", game);
    if (game.length > 0) {
      const errs = this.state.errors.filter(item => item !== "gameName");
      this.setState({ errors: errs })

    }
    const bonuses = ["REFERRAL", "ONECUT", "TWOCUT", "COUPONCODE", "GOODWILL"];
    const applicableSports = ["CASINO", "NE_GAMES"];

    if (bonuses.includes(this.state.bonusType) && applicableSports.includes(this.state.applicableSports)) {
      this.setState({ wagerAppTypeErr: false, rollAppTypeErr: false });
    }
  }

  handleReleaseCasino(casino) {
    this.handleSelection({
      key: "releaseProviders",
      value: casino,
      payload: { providerNames: casino },
      errorKeys: ["releaseProviders", "rollOverProvider"],
      bonuses: ["REFERRAL", "REGISTER", "DEPOSIT", "GOODWILL"],
      unlockApplicableType: ["CASINO", "NE_GAMES"]
    });
  }

  handleReleaseGameName(game) {
    this.handleSelection({
      key: "releaseGameName",
      value: game,
      errorKeys: ["releaseGameName", "rollOverGameName"],
      bonuses: ["REFERRAL", "COUPONCODE", "GOODWILL", "REGISTER", "DEPOSIT"],
      unlockApplicableType: ["CASINO", "NE_GAMES"]
    });
  }

  selectRolloverProvider(provider) {
    this.handleSelection({
      key: "rollOverProvider",
      value: provider,
      payload: { providerNames: provider },
      errorKeys: ["rollOverProvider", "releaseProviders"],
      bonuses: ["REFERRAL", "ONECUT", "REGISTER", "DEPOSIT", "GOODWILL"],
      unlockApplicableType: ["CASINO", "NE_GAMES"]
    });
  }

  selectRolloverGameName(game) {
    this.handleSelection({
      key: "rollOverGameName",
      value: game,
      errorKeys: ["rollOverGameName", "releaseGameName"],
      bonuses: ["REFERRAL", "ONECUT", "REGISTER", "COUPONCODE", "GOODWILL", "DEPOSIT"],
      unlockApplicableType: ["CASINO", "NE_GAMES"]
    });
    this.setState((prevState) => ({
      errors: prevState.errors.filter(error => error !== "rollOverGameName")
    }));
  }

  handleSelection({ key, value, payload, errorKeys, bonuses, unlockApplicableType }) {
    this.setState({ [key]: value, [`${key}Reset`]: false });
    this.formDataToSliceSetter(key, value);
    if (payload && value.length > 0) {
      this.props.dispatch(getProviderName(payload));
      const errs = this.state.errors.filter(item => !errorKeys.includes(item));
      this.setState({ errors: errs });
    }
    const { bonusType = '', unlockApplicableType: stateUnlockApplicableType = '', rollOverApplicableType = '' } = this.state;
    if (bonuses.includes(bonusType) && (unlockApplicableType.includes(stateUnlockApplicableType) || unlockApplicableType.includes(rollOverApplicableType))) {
      this.setState({
        wagerAppTypeErr: false,
        rollAppTypeErr: false,
        unlockSportErr: false,
        rollOverSportErr: false
      });
    }
  }

  cashbackDDLhandler(name, value) {
    this.setState({ [name]: value });
    const allocationInd = (name) =>
      bonusAllocationIpData.findIndex((item) => item.name === name);
    const casbackType = name === "cashbackType" ? value : this.state.cashbackType
    const isRealCash = casbackType === "REAL_CASH" ? true : false

    if (value === "REAL_CASH") {
      const filterAllocation = bonusAllocationIpData.filter(
        (item, i) =>
          ![
            allocationInd("depoReleaseCriteria"),
            allocationInd("minBonus"),
            allocationInd("bonusAmountRef"),
            allocationInd("minDepositRef"),
            allocationInd("bonusPercentage"),
            allocationInd("releaseCriteria"),
            allocationInd("maxBonus"),
          ].includes(i)
      );
      this.setState({ bonusAllocationFields: filterAllocation, hideBottom: true });
    }
    if (value === "BONUS_CASH") {
      const filterAllocation = bonusAllocationIpData.filter(
        (item, i) =>
          ![
            allocationInd("minBonus"),
            allocationInd("bonusAmountRef"),
            allocationInd("minDepositRef"),
            allocationInd("bonusPercentage"),
            allocationInd("maxBonus"),
          ].includes(i)
      );
      this.setState({ bonusAllocationFields: filterAllocation, hideBottom: true });
    }
  }

  handleCheckboxChange(event) {
    this.setState({ isChecked: event.target.checked })
  }
  setBonusSportSelection = (value) => {
    this.setState({ bonusSportSelection: value });
  };
  handleCashbackBetType = (val) => {
    this.setState({ cashbackBetType: val })
    this.formDataToSliceSetter("cashbackBetType", val)
  }
  handleAffiliateNames = (val) => {
    this.setState({ affilateNames: val })
    this.formDataToSliceSetter("affilateNames", val)
  }
  handleEventsSelected = (isSelected) => {
    this.setState({ isEventSelected: isSelected });
  };
  handleSportsSelected = (isSelected, compoName) => {
    this.setIds(isSelected, compoName);
  }
  handelToggle = (e) => {
    this.setState({ isToggled: e.target.checked });
  }
  handlePaymentMethod = (val) => {
    this.setState({ paymentMethod: val })
    this.formDataToSliceSetter("paymentMethod", val)
  }
  render() {
    const rolloverDeposit = this.state.bonusType === "DEPOSIT" && this.state.depoReleaseCriteria === "ROLL_OVER_CRITERIA"
    const rolloverGoodWill = this.state.bonusType === "GOODWILL" && this.state.depoReleaseCriteria === "ROLL_OVER_CRITERIA"
    const rolloverCouponCode = this.state.bonusType === "COUPONCODE" && this.state.depoReleaseCriteria === "ROLL_OVER_CRITERIA"
    const rolloverCashback = this.state.bonusType === "CASHBACK" && this.state.depoReleaseCriteria === "ROLL_OVER_CRITERIA"
    return (
      <React.Fragment>

        <div className="CMS-tab-panel active" id="create-bonus">
          <div className="CMS-tabContent">
            {this.props.bonusAlert ? <BonusCodePop onReject={this.onReject} onAccept={this.onAccept} /> : null}
            <div className="CMS-box CMS-box-content">
              <div className="row mb-10">
                <div className="col-12">
                  <div className="CMS-cardBox">
                    <p className='CMS-formLabel justify-content-center mb-10'> BONUS CREATION</p>
                    <div className="mt-20"></div>
                    <div className="row">
                      <div className="col-lg-2 col-xl-2">
                        <Select
                          title={"Opt-In"}
                          name={"option"}
                          ids={"option"}
                          info={Bonusing.Opt_In}
                          value={
                            this.state.option === "" ? "" : this.state.option
                          }
                          setValue={(event) => this.setValues(event)}
                          setCouponCode={() =>
                            this.props.dispatch(getCouponCode())
                          }
                          options={optDdlData.options}
                          errorMsg={
                            this.state.errors.includes("option") &&
                            "option is required"
                          }
                        />
                      </div>
                      {this.state.fieldsData.map((item, ind) => {
                        const maxDate = this.state.validUntil
                          ? new Date(this.state.validUntil)
                          : null;
                        const minDate = this.state.startDate
                          ? this.state.startDate
                          : new Date();
                        const maxDateArr = [maxDate, null];
                        const minDateArr = [new Date(), minDate];
                        return (
                          item.visible.includes(this.state.option) &&
                          (item.type === "ddl" ? (
                            <div className="col-lg-2 col-xl-2" key={item.id}>
                              <Select
                                title={item.title}
                                name={item.name}
                                value={this.state[item.name]}
                                setValue={(event) => this.setValues(event)}
                                ids={item.ids}
                                info={item.info}
                                setCouponCode={() =>
                                  this.props.dispatch(getCouponCode())
                                }
                                options={
                                  item.name === "bonusType" &&
                                    this.state.option === "MANUAL"
                                    ? item.options.slice(6, 8)
                                    : item.name === "bonusType" &&
                                      this.state.option === "AUTO"
                                      ? item.options.slice(0, 6) :
                                      item.name === "bonusType" &&
                                        this.state.option === "DIRECT" ?
                                        item.options.slice(8, 9)
                                        : item.name === "applicableSports" &&
                                          this.state.bonusType === "FREE_BET" ?
                                          item.options.slice(1, 2) :
                                          item.name === "applicableSports" &&
                                            this.state.bonusType === "TWOCUT" ?
                                            item.options.slice(1, 2) :
                                            item.name === "applicableSports" &&
                                              this.state.bonusType === "ONECUT" ?
                                              item.options.slice(1, 2) :
                                              item.name === "tags" &&
                                                this.state.bonusType === "GOODWILL" ?
                                                item.options.slice(11, 12)
                                                : item.name === "tags" &&
                                                  this.state.bonusType === "DEPOSIT"
                                                  ? item.options.slice(1, 6)
                                                  : item.name === "tags" &&
                                                    this.state.bonusType === "REGISTER"
                                                    ? item.options.slice(0, 1)
                                                    : item.name === "tags" &&
                                                      this.state.bonusType === "COUPONCODE"
                                                      ? item.options.slice(6, 8)
                                                      : item.name === "tags" &&
                                                        this.state.bonusType === "ONECUT"
                                                        ? item.options.slice(8, 9)
                                                        : item.name === "tags" &&
                                                          this.state.bonusType === "TWOCUT"
                                                          ? item.options.slice(9, 10)
                                                          : item.name === "tags" &&
                                                            this.state.bonusType === "REFERRAL"
                                                            ? item.options.slice(10, 11) :
                                                            item.name === "tags" &&
                                                              this.state.bonusType === "CASHBACK"
                                                              ? item.options.slice(12, 13) :
                                                              item.options.slice(0, 6)
                                }
                                errorMsg={
                                  this.state.errors.includes(item.name) &&
                                  item.errorMsg
                                }
                              />
                            </div>
                          ) : item.type === "date" ? (
                            <div className="col-lg-2 col-xl-2" key={item.id}>
                              <Datepicker
                                title={item.title}
                                name={item.name}
                                errorMsg={
                                  this.state.errors.includes(item.name) &&
                                  item.errorMsg
                                }
                                value={this.state[item.name]}
                                setValue={(date, value) =>
                                  this.setDates(date, value)
                                }
                                maxDate={maxDateArr[ind - 1]}
                                minDate={minDateArr[ind - 1]}
                                ids={item.ids}
                                info={item.info}
                                show={true}
                              />
                            </div>
                          ) : item.type === "label" ? (
                            <>
                              <div className="col-lg-2 col-xl-2">
                                {/* <div className='CMS-formGroup'> */}

                                <Input
                                  title={item.title}
                                  type={item.type}
                                  name={item.name}
                                  ids={item.ids}
                                  placeholder={item.placeholder}
                                  // regex={item.regex}
                                  maxLen={item.maxLength}
                                  errorMsg={
                                    this.state.errors.includes(item.name) &&
                                    item.errorMsg
                                  }
                                  value={this.state.result}
                                  // setValue={(event) =>
                                  //   this.setValues(event)
                                  // }
                                  generateCode={() => this.setRandomCode()}
                                  info={item.info}
                                  show={true}
                                />
                              </div>
                            </>
                          ) :
                            item.type === "textcode" ? (
                              <>
                                <div className="col-lg-2 col-xl-2">
                                  {/* <div className='CMS-formGroup'> */}

                                  <Input
                                    title={item.title}
                                    type={item.type}
                                    name={item.name}
                                    ids={item.ids}
                                    placeholder={item.placeholder}
                                    // regex={item.regex}
                                    maxLen={item.maxLength}
                                    errorMsg={
                                      this.state.errors.includes(item.name) &&
                                      item.errorMsg
                                    }
                                    value={this.props.bonusCode}
                                    setValue={(e) =>
                                      this.props.dispatch(getBonuseCode(e))
                                    }
                                    info={item.info}
                                    show={true}
                                  />
                                </div>
                              </>
                            ) : ""
                          )
                        );
                      })}
                      {
                        this.state.bonusCreateData.map((item, ind) => {
                          const values = [
                            this.state.applyExpiry,
                            this.state.bonusName,
                          ]
                          return (
                            <React.Fragment key={item.id}>
                              <div className='col-md-4 col-lg-3 col-xl-2' >
                                <Input
                                  title={item.title}
                                  name={item.name}
                                  value={values[ind]}
                                  placeholder={item.placeholder}
                                  setValue={(event) => this.setValues(event)}
                                  regex={item.regex}
                                  maxLen={item.maxLength}
                                  ids={item.ids}
                                  info={item.info}
                                  show={true}
                                  errorMsg={
                                    this.state.errors.includes(item.name) &&
                                    item.errorMsg
                                  }
                                />
                              </div>
                            </React.Fragment>
                          );
                        })
                      }
                      <div className="col-lg-2 col-xl-2">
                        <div className="CMS-formGroup">
                          <div className="CMS-formLabel">
                            {BONUS_CONSTANTS.header_affilate}
                            &nbsp;&nbsp;
                            <Tooltips info={Bonusing.affiliate} />
                          </div>
                          <MultipleSelectDDL
                            options={this.props.affiliates}
                            onSelect={(val) => this.handleAffiliateNames(val)}
                            shouldReset={this.state.resetAffiliateNames}
                            initialSelected={this.props.formData?.affilateNames || []}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-xl-2">
                        <div className="CMS-formGroup">
                          <div className="CMS-formLabel">
                            {BONUS_CONSTANTS.payment_methord}
                            &nbsp;&nbsp;
                            <Tooltips info={Bonusing.paymentMethod} />
                          </div>
                          <MultipleSelectDDL
                            options={this.props.paymentMethod}
                            onSelect={(val) => this.handlePaymentMethod(val)}
                            shouldReset={this.state.isPaymentMethodReset}
                            initialSelected={this.props.formData?.paymentMethod || []}
                          />
                        </div>
                      </div>
                      {this.state.applicableSports === "SPORTS" &&
                        <div className="col-lg-2 col-xl-2">
                          <div className="CMS-formGroup">
                            <div className="CMS-formLabel">
                              {BONUS_CONSTANTS.header_sport}
                              &nbsp;&nbsp;
                              <Tooltips info={Bonusing.sports} />
                            </div>
                            <MultipleSelectDDL
                              options={this.props.sportsList}
                              onSelect={(value) => this.setIds(value, 'createBonus')}
                              // shouldReset={this.state.clearAll}
                              initialSelected={this.props.formData?.sportId || []}
                            />
                            {/* <MultiSelectDDL
                              name="sportsSelected"
                              options={this.props.sportsList}
                              // deafaultValues={this.props.defaultSports}
                              ids={this.state.sportId}
                              setIds={(value) => this.setIds(value, "createBonus")}
                              selectedOnce={this.state.sportsSelected}
                              setSelected={this.setSelected}
                              clearAll={this.state.clearAll || false}
                              setClear={() => this.setClear()}
                            // sportDDL
                            /> */}
                            {this.state.sportsErr && (
                              <div
                                className="CMS-form-errorMsg"
                                style={{ marginTop: "-5px" }}
                              >
                                {BONUS_CONSTANTS.sportDdl_ErrMsg}
                              </div>
                            )}
                          </div>
                        </div>}
                      {this.state.applicableSports === "SPORTS" &&

                        <BonusUsageDDL
                          bonusSportSelection={this.setBonusSportSelection}
                          sportId={this.state.sportId}
                          sportList={this.props.sportsList}
                          handleEventsSelected={this.handleEventsSelected}
                          handleSportsSelected={(v) => this.handleSportsSelected(v)}
                          setInputs={this.setInputs}
                        />

                      }
                      {(this.state.applicableSports === "CASINO" || this.state.applicableSports === "NE_GAMES") &&
                        <div className="col-lg-2 col-xl-2">
                          <div className="CMS-formGroup">
                            <div className="CMS-formLabel">
                              {BONUS_CONSTANTS.header_casino}
                              &nbsp;&nbsp;
                              <Tooltips info={Bonusing.provider} />
                            </div>
                            <MultipleSelectDDL
                              options={this.props.providerNames}
                              onSelect={(value) => this.handleSelectCasino(value)}
                              shouldReset={this.state.shouldProviderFalse}
                              initialSelected={this.props.formData?.providerName || []}
                            />
                            {this.state.errors.includes("providerName") &&
                              <div
                                className="CMS-form-errorMsg"
                                style={{ marginTop: "-5px" }}
                              >
                                {BONUS_CONSTANTS.provider_ErrMsg}
                              </div>
                            }
                          </div>
                        </div>}
                      {(this.state.applicableSports === "CASINO" || this.state.applicableSports === "NE_GAMES") ?
                        this.state.providerName && this.state.providerName.length > 0 && (
                          <div className="col-lg-2 col-xl-2">
                            <div className="CMS-formGroup">
                              <div className="CMS-formLabel">
                                {BONUS_CONSTANTS.gamename}
                                &nbsp;&nbsp;
                                <Tooltips info={Bonusing.game_name} />
                              </div>
                              <MultipleSelectDDL
                                options={this.props.gameName}
                                onSelect={(value) => this.handleGameName(value)}
                                shouldReset={this.state.gameNameReset}
                                initialSelected={this.props.formData?.gameName || []}
                              />
                              {this.state.errors.includes("gameName") &&
                                <div
                                  className="CMS-form-errorMsg"
                                  style={{ marginTop: "-5px" }}
                                >
                                  {BONUS_CONSTANTS.gameName_ErrMsg}
                                </div>
                              }
                            </div>
                          </div>) : ''}
                      {
                        (this.state.tags && this.state.tags !== "register_dir") && (
                          <>
                            <div className='col-md-4 col-lg-3 col-xl-2'>
                              <div className='CMS-formGroup'>
                                <div className='CMS-formLabel'>
                                  {"Allow Tags"}
                                  &nbsp;&nbsp;
                                  <Tooltips info={"Showing Allow Tags"} />
                                </div>
                                <MultipleSelectDDL
                                  multiName={["allowTags"]}
                                  options={this.props.allowTaxData}
                                  onSelect={(value) => this.setSelectedAllowtTags(value)}
                                  shouldReset={this.state.allowTagsClearAll}
                                  initialSelected={this?.props?.formData?.allowTags || []}
                                />
                              </div>
                            </div>
                            <div className='col-md-4 col-lg-3 col-xl-2'>
                              <div className='CMS-formGroup'>
                                <div className='CMS-formLabel'>
                                  {"Deny Tags"}
                                  &nbsp;&nbsp;
                                  <Tooltips info={"Showing Deny Tags"} />
                                </div>
                                <MultipleSelectDDL
                                  multiName={["denyTags"]}
                                  options={this.props.allowTaxData}
                                  onSelect={(value) => this.setSelectedDenytTags(value)}
                                  shouldReset={this.state.denyTagsClearAll}
                                  initialSelected={this?.props?.formData?.denyTags || []}
                                />
                              </div>
                            </div>
                          </>
                        )
                      }
                      {
                        this.state.option === "AUTO" &&
                        (this.state.bonusType === "ONECUT" || this.state.bonusType === "TWOCUT") &&
                        <div className="col-lg-2 col-xl-2">
                          <div className="CMS-formGroup" style={{}}>
                            <div className="CMS-formLabel">&nbsp;</div>
                            <div className="CMS-formGroup formGroup-inline mb-0">
                              <div className="CMS-formControl">
                                <div className="CMS-toggleSwitch">
                                  <input
                                    id="toggleActive"
                                    name="toggleActive"
                                    type="checkbox"
                                    value={this.state.isToggled}
                                    checked={this.state.isToggled}
                                    style={{ zoom: 1.5 }}
                                    onChange={this.handelToggle}
                                  />
                                  <label htmlFor="toggleActive"></label>
                                </div>
                              </div>
                              <div className="CMS-formLabel pl-10">
                                Add to Withdrawable balance
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      {
                        this.state.bonusType === "CASHBACK" &&
                        <BonusDDLs
                          bonusAppFor={this.state.bonusAppFor}
                          bettingType={this.state.bettingType}
                          cashbackFrequency={this.state.cashbackFrequency}
                          cashbackType={this.state.cashbackType}
                          handleChange={(name, value) => this.cashbackDDLhandler(name, value)}
                          isChecked={this.state.isChecked}
                          onCheckboxChange={(e) => this.handleCheckboxChange(e)}
                        />
                      }
                    </div>
                  </div>
                  {this.state.bonusType === "ONECUT" || this.state.bonusType === "TWOCUT" ? null : <div className="CMS-cardBox">
                    <p className='CMS-formLabel justify-content-center mb-10'>BONUS ALLOCATION</p>
                    <div className="mt-20"></div>
                    <div className="row">
                      {
                        this.state.bonusAllocationFields.map((item, ind) => {
                          return (
                            item.visible.includes(this.state.option) && (
                              item.type === "ddl" ?
                                <div className="col-lg-2 col-xl-2" key={item.id}>
                                  <Select
                                    title={item.title}
                                    name={item.name}
                                    value={this.state[item.name]}
                                    setValue={(event) => this.setValues(event)}
                                    options={item.options}
                                    errorMsg={
                                      this.state.errors.includes(item.name) &&
                                      item.errorMsg
                                    }
                                    info={item.info}
                                  />
                                </div>
                                : <>
                                  <div className='col-md-4 col-lg-3 col-xl-2' >
                                    <Input
                                      title={item.title}
                                      name={item.name}
                                      value={this.state[item.name]}
                                      placeholder={item.placeholder}
                                      setValue={(event) => this.setValues(event)}
                                      regex={item.regex}
                                      maxLen={item.maxLength}
                                      ids={item.ids}
                                      info={item.info}
                                      show={true}
                                      errorMsg={
                                        this.state.errors.includes(item.name) &&
                                        item.errorMsg
                                      }
                                    />
                                  </div>
                                </>)
                          )
                        })
                      }

                      {this.state.bonusType === "CASHBACK" &&
                        <div className='col-md-4 col-lg-3 col-xl-2'>
                          <div className="CMS-formLabel">
                            {"Bet Type"}
                            &nbsp;&nbsp;
                            <Tooltips info={"Select Bet Type"} />
                          </div>
                          <MultipleSelectDDL
                            options={CasbackBetType}
                            onSelect={(val) => this.handleCashbackBetType(val)}
                            shouldReset={this.state.clearCashBackBetType}
                            initialSelected={this?.props?.formData?.cashbackBetType || []}
                          />
                        </div>}


                    </div>
                    {
                      this.state.cashbackType !== "REAL_CASH" &&
                      this.state.bonusType !== "FREE_BET" &&
                      this.state.bonusType !== "ONECUT" &&
                      this.state.bonusType !== "TWOCUT" &&
                      this.state.hideBottom && (
                        <div className="row">
                          {rolloverDeposit || rolloverCouponCode || rolloverGoodWill ? (
                            <AllocationComponent
                              data={rolloverBAdata}
                              fieldClass={"col-6"}
                              errors={this.state.errors}
                              setValues={(e) => this.setValues(e)}
                              state={this.state}
                              titleShow={true}
                            />
                          ) : (
                            <AllocationComponent
                              data={bonusAllocationData}
                              fieldClass={"col-6"}
                              errors={this.state.errors}
                              setValues={(e) => this.setValues(e)}
                              state={this.state}
                              titleShow={true}
                            />
                          )}
                          {this.state.bonusType === "REFERRAL" ? (
                            <AllocationComponent
                              data={referalAllocationData}
                              fieldClass={"col-4"}
                              errors={this.state.errors}
                              setValues={(e) => this.setValues(e)}
                              state={this.state}
                            />
                          ) : rolloverDeposit || rolloverCouponCode || rolloverGoodWill || rolloverCashback ? (
                            <AllocationComponent
                              data={rolloverTimesData}
                              fieldClass={"col-4"}
                              errors={this.state.errors}
                              setValues={(e) => this.setValues(e)}
                              state={this.state}
                              titleShow={true}
                              rollApplicableType={this.state.rollOverApplicableType}
                              rollOver
                              showDdl
                              sportsList={this.props.rcSportsList}
                              rollSportids={this.state.rollOverSportId}
                              rollDeafaultValues={this.props.rcDefaultSports}
                              rollSetIds={(value) => this.setRollOverSportId(value)}
                              rollSelectedOnce={this.state.rollOversportsSelected}
                              rollSetSelected={this.setRollOverSportSelected}
                              rollClearAll={this.state.clearRollOverSports || false}
                              rollSetClear={() => this.setRollOverSportClear()}
                              rollOverSportErr={this.state.rollOverSportErr}
                              rollAppTypeErr={this.state.rollAppTypeErr}
                              providerOptions={this.props.providerNames}
                              releaseGameName={this.props.releaseGameNames}
                              selectRolloverProvider={(value) => this.selectRolloverProvider(value)}
                              selectRolloverGameName={(value) => this.selectRolloverGameName(value)}
                              initialRolloverProvider={this.props.formData?.rollOverProvider || []}
                              initialRolloverGameName={this.props.formData?.rollOverGameName || []}
                              rollOverGameNameReset={this.state.rollOverGameNameReset}
                              rollOverProviderReset={this.state.rollOverProviderReset}
                              applicableSports={this.state.applicableSports}
                            />
                          ) : (
                            <AllocationComponent
                              data={releaseCriteriaData}
                              fieldClass={"col-4"}
                              errors={this.state.errors}
                              setValues={(e) => this.setValues(e)}
                              state={this.state}
                              titleShow={true}
                              showDdl={true}
                              applicableType={this.state.unlockApplicableType}
                              sportsList={this.props.rcSportsList}
                              ids={this.state.unlockApplicableSportsIds}
                              deafaultValues={this.props.rcDefaultSports}
                              setIds={(value) => this.setUnlockSportId(value)}
                              selectedOnce={this.state.unlocksportsSelected}
                              setSelectedRC={this.setUnlockSportSelected}
                              clearAll={this.state.clearUnlockSports || false}
                              setClear={() => this.setUnlockSportClear()}
                              unlockSportErr={this.state.unlockSportErr}
                              wagerAppTypeErr={this.state.wagerAppTypeErr}
                              providerOptions={this.props.providerNames}
                              shouldProviderFalse={this.state.shouldProviderFalse}
                              onSelectProvider={(value) => this.handleReleaseCasino(value)}
                              initialSelectedProvider={this.props.formData?.releaseProviders || []}
                              releaseGameName={this.props.releaseGameNames}
                              releaseGameNameFalse={this.state.gameNameReset}
                              selectReleaseGamename={(value) => this.handleReleaseGameName(value)}
                              initiallyGameName={this.props.formData?.releaseGameName || []}
                              applicableSports={this.state.applicableSports}
                            />
                          )}
                        </div>
                      )}
                  </div>}
                  {(this.state.depoReleaseCriteria === "ROLL_OVER_CRITERIA" || this.state.cashbackType === "REAL_CASH") ? "" :
                    <div className="CMS-cardBox">
                      <p className='CMS-formLabel justify-content-center mb-10'>BONUS USAGE</p>
                      <div className="mt-20"></div>
                      <div className="row">
                        {
                          this.state.bonusUsageFields.map((item, ind) => {
                            return (
                              <div className='col-md-4 col-lg-3 col-xl-2' >
                                <Input
                                  title={item.title}
                                  name={item.name}
                                  value={this.state[item.name]}
                                  placeholder={item.placeholder}
                                  setValue={(event) => this.setValues(event)}
                                  regex={item.regex}
                                  maxLen={item.maxLength}
                                  ids={item.ids}
                                  info={item.info}
                                  show={true}
                                  errorMsg={
                                    this.state.errors.length > 0 && this.state.errors.includes(item.name) ?
                                      item.errorMsg : item.name === "maxWinPercent"
                                      && this.state.maxwinError && "Max Winning % should not be greater than 100%"
                                  }
                                />
                              </div>
                            )

                          })
                        }
                      </div>
                    </div>}
                  <div className="mt-20"></div>
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                      <div className="CMS-btnContainer">
                        <button
                          id="btnBonusCreate"
                          style={{ width: "200" }}
                          className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${(this.state.disableBtn || this.props.viewOnlyuser) ? 'disable-button' : ''}`}
                          type="button"
                          onClick={(e) => this.onSubmit(e)}
                        >
                          {BONUS_CONSTANTS.createBonus_btn}
                        </button>
                        <button
                          onClick={() => this.emptyStatehandler()}
                          className='CMS-btn CMS-btnQuaternary active CMS-btnMedium'
                          type='reset'

                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="CMS-filter-result" id="result">
            <div className="row">
              {filterDdl.map((item) => {
                return (
                  <div className="col-lg-3 col-xl-3">
                    <Select
                      title={item.title}
                      name={item.name}
                      value={this.state[item.name]}
                      setValue={(event) => this.setValues(event)}
                      options={item.options}
                      ids={item.ids}
                      info={item.info}
                    // hideStaticOption={item.name==="filterBonusStatus"?true:false}
                    />
                  </div>
                );
              })}
              <div className="col">
                <div className='CMS-formGroup'>
                  <div className='CMS-formLabel'>
                    {COMMON_CONSTANTS.searchBtn}
                    &nbsp;&nbsp;
                    <Tooltips info={Bonusing.bonus_search} />
                  </div>
                  <div className="d-flex">
                    <input
                      type="text"
                      placeholder="Enter Bonus Code"
                      id="myInput"
                      name="searchData"
                      value={this.state.searchData}
                      onChange={(e) => this.handleInput(e)}
                      ref={this.myRef}
                    />

                    <span className='material-icons search search-icon-AFF pointer'
                      onClick={(e) => this.handleSearch(e)}
                    >{COMMON_CONSTANTS.search_icon}</span>

                  </div>
                  {this.state.bonusCodeErr && (
                    <div className='text-danger'>
                      {BONUS_CONSTANTS.bonusCode_ErrMsg}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="CMS-pagination">
              <Vpagination
                data={this.props.bonusData.data}
                totalRecords={this.props.bonusData.totalRecords}
                paginationFirstValue={this.props.paginationFirstValue}
                paginationSecondValue={this.props.paginationSecondValue}
                setPaginationFirstValue={setPaginationFirstValue}
                setPaginationSecondValue={setPaginationSecondValue}
                recordsShow={this.props.recordsShow}
                setRecordsShow={setRecordsShow}
                isVisible={this.props.isBonusTableLoaded}
                setIsVisible={setIsBonusTableLoaded}
                csvData={this.state.csvData}
                exportPDF={() => this.exportPDF()}
                callApi={(p, i, d) => this.callApi(p, i, d)}
                csvFileName={"Create Bonus Report"}
                resetCall={this.state.resetCall}
                reloadRequired={false}
                payloadData={this.state.payloadData}
              />
            </div>
            {!this.props.isBonusTableLoaded ? <Skelton vLen={5} hLen={7} /> : <BonusTable
              handleValidDate={this.handleValidDate}
              bonusAdd={this.state.bonusAdd}
              openEditBox={this.state.openEditBox}
              manageIDs={this.state.manageIDs}
              handleActiveDeactive={this.handleActiveDeactive}
              statusValue={this.state.statusValue}
              updateTableToDocs={(k, st, kt) => this.updateTableToDocs(k, st, kt)}
              onEditSubmit={this.onEditSubmit}
              handleEditClose={this.handleEditClose}
              handleEditOpen={this.handleEditOpen}
              editDateErr={this.state.editDateErr}
              btags={this.props.affiliates}
            />}
          </div>
        </div>

      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    bonusData: state.bonus.bonusData,
    bonusDataDuplicate: state.bonus.bonusDataDuplicate,
    userId: state.playersearch.userId,
    paginationFirstValue: state.bonus.paginationFirstValue,
    paginationSecondValue: state.bonus.paginationSecondValue,
    recordsShow: state.bonus.recordsShow,
    isVisible: state.bonus.isVisible,
    sportsList: state.bonus.bonusSportsData,
    bonusCode: state.bonus.bonusCode,
    formData: state.bonus.formData,
    docsData: state.bonus.docsData,
    createBonusErrors: state.bonus.createBonusErrors,
    defaultSports: state.bonus.defaultSports,
    disableBtn: state.bonus.disableBtn,
    bonusAlert: state.bonus.bonusAlert,
    isBonusCreated: state.bonus.isBonusCreated,
    rcSportsList: state.bonus.rcSportsList,
    rcDefaultSports: state.bonus.rcDefaultSports,
    rollOverDefaultSports: state.bonus.rollOverDefaultSports,
    allowTaxData: state.bonus.allowTaxData,
    providerNames: state.betHistorySliceReducer.providerNames,
    gameName: state.bonus.gameName,
    releaseGameNames: state.bonus.releaseGameName,
    isBonusTableVisible: state.bonus.isBonusTableVisible,
    isBonusTableLoaded: state.bonus.isBonusTableLoaded,
    countryInBonusPopup: state.BoostedOddsSlice.countryInBonusPopup,
    countries: state.BoostedOddsSlice.countries,
    leagues: state.BoostedOddsSlice.leagues,
    events: state.BoostedOddsSlice.events,
    events: state.BoostedOddsSlice.events,
    eventsOpt: state.BoostedOddsSlice.eventsOpt,
    leaguesInBonus: state.BoostedOddsSlice.leaguesInBonus,
    eventBySportCountryLeague: state.BoostedOddsSlice.eventBySportCountryLeague,
    openDialog: state.bonus.openDialog,
    affiliates: state.bonus.affiliates,
    onlySportsSelected: state.BoostedOddsSlice.onlySportsSelected,
    countryInBonus: state.BoostedOddsSlice.countryInBonus,
    sportsSelectedInDialog: state.BoostedOddsSlice.sportsSelectedInDialog,
    viewOnlyuser: state.dashboard.viewOnlyuser,
    paymentMethod: state.bonus.paymentMethod,

  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators({}, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateBonusNew);