import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { bonusApi } from "../../sharedfiles/EndpointConfig";
import { cleanObject, toaster } from "../../sharedfiles/helper";
export const CreateBonusSlice = createSlice({
  name: "bonus",
  initialState: {
    bonusData: [],
    bonusDataDuplicate: [],
    snackbarOpen: false,
    showTable: false,
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    recordsShow: [1, 25],
    isVisible: false,
    sportsList: [],
    sslList: [],
    bonusCode: [],
    formData: {},
    docsData: [],
    createBonusErrors: [],
    defaultSports: [],
    disableBtn: false,
    isDialogOpen: false,
    bonusAlert: false,
    isBonusCreated: false,
    bonusAwardData: [],
    userPaginationFirstValue: 1,
    userPaginationSecondValue: 25,
    userIsVisible: false,
    rcSportsList: [],
    rcDefaultSports: [],
    rollOverDefaultSports: [],
    allowTaxData: [],
    sportsData: [],
    gameName: [],
    releaseGameName: [],
    editSaveDisable: false,
    isBonusTableVisible: false,
    isBonusTableLoaded: false,
    openDialog: false,
    affiliates: [],
    bonusSportsData: [],
    paymentMethod: [],
  },
  reducers: {
    setCreateBonus: (state, action) => {
      state.bonusData = action.payload;
    },
    setDupeCreateBonus: (state, action) => {
      state.bonusDataDuplicate = action.payload;
    },
    setSnackbar: (state, action) => {
      state.snackbarOpen = action.payload;
    },
    setShowTable: (state, action) => {
      state.showTable = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
    setSportsList: (state, action) => {
      state.sportsList = action.payload;
    },
    setSslList: (state, action) => {
      state.sslList = action.payload;
    },
    setBonusCode: (state, action) => {
      state.bonusCode = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setDocsData: (state, action) => {
      state.docsData = action.payload;
    },
    setCreateBonusErrors: (state, action) => {
      state.createBonusErrors = action.payload;
    },
    setDefaultSports: (state, action) => {
      state.defaultSports = action.payload;
    },
    setDisableBtn: (state, action) => {
      state.disableBtn = action.payload;
    },
    setIsDialogOpen: (state, action) => {
      state.isDialogOpen = action.payload;
    },
    setBonusAlert: (state, action) => {
      state.bonusAlert = action.payload;
    },
    setIsBonusCreated: (state, action) => {
      state.isBonusCreated = action.payload;
    },
    setBonusAwardData: (state, action) => {
      state.bonusAwardData = action.payload;
    },
    setUserFirstPage: (state, action) => {
      state.userPaginationFirstValue = action.payload;
    },
    setUserSecondPage: (state, action) => {
      state.userPaginationSecondValue = action.payload;
    },
    setUserIsVisible: (state, action) => {
      state.userIsVisible = action.payload;
    },
    setRcSportsList: (state, action) => {
      state.rcSportsList = action.payload;
    },
    setRcDefaultSports: (state, action) => {
      state.rcDefaultSports = action.payload;
    },
    setRollOverDefaultSports: (state, action) => {
      state.rollOverDefaultSports = action.payload;
    },
    setAllowTaxData: (state, action) => {
      state.allowTaxData = action.payload;
    },
    setSportsData: (state, action) => {
      state.sportsData = action.payload;
    },
    setGameName: (state, action) => {
      state.gameName = action.payload;
    },
    setReleaseGameName: (state, action) => {
      state.releaseGameName = action.payload;
    },
    setEditSaveDisable: (state, action) => {
      state.editSaveDisable = action.payload;
    },
    setIsBonusTableVisible: (state, action) => {
      state.isBonusTableVisible = action.payload;
    },
    setIsBonusTableLoaded: (state, action) => {
      state.isBonusTableLoaded = action.payload;
    },
    setOpenDialog: (state, action) => {
      state.openDialog = action.payload;
    },
    setAffiliates: (state, action) => {
      state.affiliates = action.payload;
    },
    setBonusSportsData: (state, action) => {
      state.bonusSportsData = action.payload;
    },
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
  },
});
export const {
  setCreateBonus,
  setSnackbar,
  setShowTable,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  setIsVisible,
  setSportsList,
  setSslList,
  setBonusCode,
  setFormData,
  setDocsData,
  setCreateBonusErrors,
  setDefaultSports,
  setDisableBtn,
  setDupeCreateBonus,
  setIsDialogOpen,
  setBonusAlert,
  setIsBonusCreated,
  setBonusAwardData,
  setUserFirstPage,
  setUserSecondPage,
  setUserIsVisible,
  setRcSportsList,
  setRcDefaultSports,
  setRollOverDefaultSports,
  setAllowTaxData,
  setSportsData,
  setGameName,
  setReleaseGameName,
  setEditSaveDisable,
  setIsBonusTableVisible,
  setIsBonusTableLoaded,
  setOpenDialog,
  setAffiliates,
  setBonusSportsData,
  setPaymentMethod,
} = CreateBonusSlice.actions;

export const getBonuseCode = (e) => (dispatch) => {
  const { value } = e.target;
  //  let valueCode = this.props.couponCode
  dispatch(setBonusCode(value));
}

export const getBonus = (params, errMsg) => (dispatch) => {
  dispatch(setSnackbar(false));
  var url = `${bonusApi.getBonus}`;
  apiCaller(url, "POST", params, null, errMsg)
    .then((response) => {
      dispatch(setDisableBtn(true));
      if (response !== null && response) {
        if (response.status === 200) {
          if (response.message === "CREATE_BONUS_SUCCESS") {
            toaster.success("SUCCESS");
          }
          else {
            toaster.error(response.message);
          }
          dispatch(setIsBonusTableVisible(true));
          dispatch(setSnackbar(true));
          dispatch(setShowTable(true));
          dispatch(setIsBonusCreated(true));
        }
        if (
          response.status === 500 ||
          response.status === 401 ||
          response.status === 400
        ) {
          dispatch(setSnackbar(true));
          toaster.error("FAILED");
        }
      }
    })
    .catch((error) => {
      dispatch(setDisableBtn(true));
      const response = error.response
      const hydratedText =
        response &&
        response
          .split('_')
          .map((word) =>
            word[0].toUpperCase().concat(word.slice(1).toLowerCase()),
          )
          .join(' ')
      toaster.error(hydratedText);
    });
};
export const updateBonusStatus = (params) => (dispatch) => {
  //dev.platform-pbs1.sportsit-tech.net:443/bonus/update
  const url = `${bonusApi.updateBonus}`;
  const obj = {
    bonusId: params.bonusId,
    bonusStatus: params.bonusStatus,
    validUntil: params.validUntil
  };
  apiCaller(url, "PUT", cleanObject(obj))
    .then((res) => toaster.success("SUCCESS"))
    .catch((err) => toaster.error("FAILED"));
};

export const fetchBonusList = (params) => (dispatch) => {
  const cleanObject = (obj) => {
    for (var propName in obj) {
      if (
        obj[propName] == null ||
        obj[propName] === undefined ||
        obj[propName] === "" ||
        obj[propName].length === 0
      ) {
        delete obj[propName];
      }
    }
    return obj;
  };
  const clenData = cleanObject(params);
  var obj = params
  const { bonusType, statusType, allocationType, bonusCode } = clenData;
  //dev.platform-pbs.sportsit-tech.net:443/bonus/all?pageNumber=0&itemsPerPage=25
  //dev.platform-pbs.sportsit-tech.net:443/bonus/all?bonusType=FREE_BET&bonusStatus=EXPIRED&allocationType=AUTO&pageNumber=0&itemsPerPage=25
  const bt =
    bonusType !== "" && bonusType !== undefined
      ? `bonusType=${bonusType}&`
      : "";
  const st =
    statusType !== "" && statusType !== undefined
      ? `bonusStatus=${statusType}&`
      : "";
  const at =
    allocationType !== "" && allocationType !== undefined
      ? `allocationType=${allocationType}&`
      : "";
  const bc =
    bonusCode !== "" && bonusCode !== undefined
      ? `bonusCode=${bonusCode}&`
      : "";
  let filterUrl = `${bonusApi.getBonusummery}?${bt}${st}${at}${bc}pageNumber=${params.pageNumber}&itemsPerPage=${params.itemsPerPage}`;
  const isBonusCode = obj.hasOwnProperty("bonusCode");
  apiCaller(filterUrl, "GET", {})
    .then((res) => {
      if (res.status === 200) {
        if (isBonusCode && res.data.data?.length === 0) {
          dispatch(setBonusAlert(true))
        } else {
          dispatch(setCreateBonus(res.data));
          dispatch(setEditSaveDisable(true));
          dispatch(setIsBonusTableVisible(false));
          dispatch(setIsBonusTableLoaded(true))
        }
        dispatch(setDupeCreateBonus(res.data));
        // updateTableToDocs();
        dispatch(setIsVisible(true));
      }
    })
    .catch((err) => {
      if (err.statusCode !== 200 && isBonusCode) {
        dispatch(setBonusAlert(true))
      }
    });
};

export const fetchSportsList = () => (dispatch) => {
  const url = `${bonusApi.fetchSports}`;
  apiCaller(url, "GET", {}).then((res) => {
    const data = [];
    const sslData = [];
    const bonusSportsData = [];
    res.data.map((item, ind) => {
      data.push({
        name: item.sportName,
        value: item.sportId,
        sportId: item.sportId,
        id: item.sportId,
      });
      sslData.push({
        label: item.sportName,
        value: item.sportName,
        sportId: item.sportId,
        id: ind + 1,
      });
      bonusSportsData.push({
        label: item.sportName,
        value: item.sportId,
        id: ind + 1,
      });
    });
    dispatch(setSportsData(sslData));
    dispatch(setSportsList(data));
    dispatch(setRcSportsList(data));
    dispatch(setSslList(sslData));
    dispatch(setBonusSportsData(bonusSportsData));
    const defaultSports = data.map(sport => sport.id)
    dispatch(setDefaultSports(defaultSports))
    dispatch(setRcDefaultSports(defaultSports))
    dispatch(setRollOverDefaultSports(defaultSports))
  });
};

export const getCouponCode = () => (dispatch) => {
  let url = bonusApi.getCouponCode;
  apiCaller(url, "GET")
    .then((response) => {
      if (response && response.data !== null) {
        dispatch(setBonusCode(response.data));
      }
    })
    .catch((err) => console.log(err));
};

export const getBonusAwardDetail = (id, pageNum, itemsPerPage) => (dispatch) => {
  let url = bonusApi.bonusAwardDetails(id, pageNum, itemsPerPage)
  apiCaller(url, "GET")
    .then((response) => {
      if (response && response.data !== null) {
        dispatch(setBonusAwardData(response.data));
      }
    })
    .catch((err) => console.log(err));
}

export const AllowTax = (params) => (dispatch) => {
  const url = bonusApi.allowTax;
  let obj = {
    bonusType: params.bonusType,
    tags: params.tags
  };
  apiCaller(url, "POST", obj)
    .then((response) => {
      if (response !== null && response) {
        let data = response.map((ele, id) => ({
          id: id,
          label: ele.tagName,
          value: ele.isSystemTag ? ele.tagName : ele.tagId,
        }));
        dispatch(setAllowTaxData(data));
      }
    })
    .catch((err) => {
      console.error(err);
    });
};


export const getProviderName = (parms, providerType) => (dispatch) => {
  const payload = {
    providerNames: parms.providerNames
  };
  const url = bonusApi.providerName(parms.providerNames);
  apiCaller(url, "POST", payload)
    .then((res) => {
      if (res.status === 200) {
        const newDataArray = [];
        res.data.forEach(item => {
          const { gameName, ...rest } = item;
          const newData = { label: gameName, value: gameName, ...rest };
          newDataArray.push(newData);
        });
        if (providerType == "bonusCreation") {
          dispatch(setGameName(newDataArray));
        } else {
          dispatch(setReleaseGameName(newDataArray));
        }
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getAffiliateNames = (params) => (dispatch) => {
  const url = bonusApi.getAffiliates;
  apiCaller(url, "GET")
    .then((res) => {
      const transformedData = res.map((affiliate, index) => ({
        id: index,
        label: affiliate.affiliateName,
        // brandId: affiliate.brandId,
        value: affiliate.btag,
      }));
      dispatch(setAffiliates(transformedData))
    })
    .catch((err) => {
      console.error(err)
    })
}
export const getPaymentMethod = (params) => (dispatch) => {
  const url = bonusApi.getPaymentMethod;
  apiCaller(url, "GET")
    .then((res) => {
      console.log("res: ", res);
      const transformedData = res?.data?.map((payment, index) => ({
        id: index,
        label: payment.tagName,
        value: payment.tagName,
      }));
      dispatch(setPaymentMethod(transformedData))
    })
    .catch((err) => {
      console.error(err)
    })
}


export default CreateBonusSlice.reducer;
