/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./App.css";
import "./assets/css/cms-styles.scss";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useHistory,
  Link,
  Redirect,
  BrowserRouter
} from "react-router-dom";
import { getEvenId, notificationSubscribe, setSocketData, setSocketNotification } from "../src/components/AppSlice";
import SubMenu from "./components/SubMenu";
import { setOpen, setDefaultPage } from "./components/AppSlice";
import Login from "./components/Login/login";
import { setOpenTabs, tabManager } from "./components/sidebar/sidebarSlice";
import {
  getBrand,
} from "./components/Dashboard/dashboardSlice";
import { getPendingNotifications } from "./components/PendingWithrawals/pendingWithrawalSlice";
import { useRef } from "react";
import NavigationTabs from "./components/Common/NavigationTabs/NavigationTabs";
import ForceLogout from "./components/Login/ForceLogout";
import TotpAuth from "./components/Auth/TotoAuth";
import TotpLogin from "./components/Auth/TotpLogin";
import AddNewPassword from "./components/Login/AddNewPassword";
import Toaster from "../src/sharedfiles/Toaster"
import { LOGIN_CONSTANTS } from "./sharedfiles/Constants";
import BiReport from "./components/Reports/BiReports/biReport";
import BiDashboard from "./components/BiDashboard/BiDashboard";
import "./sharedfiles/Socket";
import AllReport from "./components/Reports/BiReports/AllBiReport";
import { BiReportsDev } from "./components/Reports/BiReports/biDataDev";
import { BiReportsQA } from "./components/Reports/BiReports/biDataQA";
import { BiReportsProd } from "./components/Reports/BiReports/biDataProd";
import { setBiReportData, setVipBiReportData } from "./components/Reports/BiReports/bireportSlice";
import notty from './sounds/notification.mp3';
import * as Tone from 'tone';
import { notySubscribe, notyUnSubscribe, toaster, urlB64ToUint8Array } from "./sharedfiles/helper";
import { subscribeNotification } from "./sharedfiles/Socket";
import * as serviceWorker from "./serviceWorker";
import { dashboardReportDev } from "./components/BiDashboard/devDashData";
import { dashboardReportQA } from "./components/BiDashboard/qaDashData";
import { dashboardReportProd } from "./components/BiDashboard/prodDashData";
import { setBiDashboardData } from "./components/BiDashboard/biDashSlice";
import AllDashboardReport from "./components/BiDashboard/AllDashboardRepo";
import starbetLogo from "./starbet-logo.svg"
import Forbidden from "./components/Routes/Forbidden";
import ProtectedRoute from "./components/Routes/ProtectedRoutes";
import NoPermissionRoutes from "./components/Routes/NoPermissionRoutes";
import "./assets/css/styles/components/dashboard-new.scss"
import NElogo from "./assets/images/logo-mobile.svg"
import { VipBiReports } from "./components/VipReports/ProdVipReport";
import VipRepo from "./components/VipReports/VipRepo";
const App = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [brand, setBrand] = useState("");
  const history = useHistory();
  const [nToggler, setNToggler] = useState(false);
  const nRef = useRef(null);
  const token = localStorage.getItem("token") && localStorage.getItem("merchantId");
  const brandId = parseInt(localStorage.getItem("brandId"))
  const handleSidebarClick = () => {
    dispatch(setOpen(!props.open));
  };
  const { starbetLogoPermission, isDashboard } = useSelector((state) => state.dashboard)
  const userId = localStorage.getItem("logUserId")
  const brandHandler = (e) => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        registrations.forEach(function (registration) {
          registration.unregister()
            .then((unregister) => {
              dispatch(notyUnSubscribe())
            })
            .catch((error) => {
              console.log('Service Worker unregistration failed:', error);
            });
        });
      }).then(function () {
        navigator.serviceWorker.register('/sw.js', { scope: '/public/' });
      });
    }
    localStorage.setItem("merchantkey", e.target.value);
    setBrand(e.target.value ? e.target.value : "");
    if (isDashboard) {
      window.location = "/dashboard ";
    }
    else {
      window.location = "/newDashboard ";
    }
    dispatch(tabManager("DASHBOARD", []));
    dispatch(setOpenTabs(["MANAGEMENT REPORTING"]))
    localStorage.setItem("userId", "");
    const item = props.brandData.filter(
      (item) => item.merchantKey === e.target.value
    )[0];
    const userID = item.brandId;
    const currency = item.currency;
    const logUserId = props.platformUserDetails.platformUsers.id
    localStorage.setItem("brandId", userID);
    localStorage.setItem("currency", currency);
    localStorage.setItem("logUserId", logUserId);

  };

  const requestPermission = () => {
    const must = token && userId
    if ('Notification' in window && navigator.serviceWorker) {
      Notification.requestPermission(status => {
        if (status === "granted" && must) {
          dispatch(notySubscribe())
        }
        if (status !== "granted" && must) {
          dispatch(notyUnSubscribe())
        }
      });
    }
  }

  const callServiceWorker = async () => {
    const key = 'BBYCxwATP2vVgw7mMPHJfT6bZrJP2iUV7OP_oxHzEcNFenrX66D8G34CdEmVULNg4WJXfjkeyT0AT9LwavpN8M4='
    const applicationServerKey = urlB64ToUint8Array(key);
    navigator.serviceWorker.register('/sw.js', { scope: '/public/' })
      .then(registration => {
        registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey
        }).then((subscription) => {

          const endpoint = subscription.endpoint;
          localStorage.setItem("endpoint", endpoint)

          const key = subscription.getKey("p256dh");

          const uint8Array = new Uint8Array(key);
          const base64String = btoa(String.fromCharCode.apply(null, uint8Array));
          localStorage.setItem("Publickey", base64String)

          const auth = subscription.getKey("auth");

          const uint8Array2 = new Uint8Array(auth);
          const base64String2 = btoa(String.fromCharCode.apply(null, uint8Array2));
          localStorage.setItem("auth", base64String2)
          requestPermission()
        })
        // console.log("Service worker registration succeeded:", registration);
      })
      .catch(error => {
        console.log('Error registering Service Worker:', error);
      });
  }


  useEffect(() => {
    if ('serviceWorker' in navigator) {
      callServiceWorker()
    }
  }, [])

  const handleLogOut = () => {
    localStorage.removeItem("merchantId");
    localStorage.removeItem("token");
    localStorage.removeItem("tab");
    localStorage.removeItem("userId");
    localStorage.setItem("userId", "");
    localStorage.removeItem("tab");
    localStorage.removeItem("isExist");
    localStorage.removeItem("isMasking");
    localStorage.clear();
    // props.history.push("/login")
  };


  const handleNotification = (event) => {
    if (nRef.current && !nRef.current.contains(event.target)) {
      setNToggler(false);
    }
  };

  useEffect(() => {
    let mounting = true;
    if (mounting) {
      if (token) {
        dispatch(getBrand(props));
      }
      document.addEventListener("mousedown", handleNotification);
    }
    return () => {
      mounting = false;
      document.removeEventListener("mousedown", handleNotification, false);
    };
  }, []);

  useEffect(() => {
    if (!token) {
      handleLogOut();
    }
  }, [token])

  const navigateToDestination = (page) => {

    if (page === "deposits") {
      dispatch(tabManager("/pendingdeposits", props.tabStack));
    } else {
      const data =
        props.userPagePermissions.length > 0 &&
        [...props.userPagePermissions].map((item) => item.permissionName);


      if (data.includes("PAYMENT_PENDING_WITHDRAWALS_OFFLINE")) {
        dispatch(tabManager("/pendingwithdrawalsoffline", props.tabStack));
      }

      if (data.includes("PAYMENT_PENDING_WITHDRAWAL")) {
        dispatch(tabManager("/pendingwithdrawals", props.tabStack));
      }

      if (data.includes("ADMIN_BECOME_AFFILIATE") && (props.notifications?.pendingAffiliateUserRequestCount?.length > 0)) {
        dispatch(tabManager("/becomeaffiliate", props.tabStack));
      }

    }
  };
  const routeCheck = () => {
    const data =
      props.userPagePermissions.length > 0 &&
      [...props.userPagePermissions].map((item) => item.permissionName);
    let path = "";
    if (data && data.length > 0) {
      if (data.includes("PAYMENT_PENDING_WITHDRAWALS_OFFLINE")) {
        path = "/pendingwithdrawalsoffline"
      }
      if (data.includes("PAYMENT_PENDING_WITHDRAWAL")) {
        path = "/pendingwithdrawals"
      }
      if ((data.includes("ADMIN_BECOME_AFFILIATE")) && (props.notifications?.pendingAffiliateUserRequestCount?.length > 0)) {
        path = "/becomeaffiliate"
      }
    }
    return path;
  }

  useEffect(() => {
    if (
      props.notifications?.totalCount &&
      props.notifications?.totalCount !== undefined &&
      props.notifications?.totalCount > 0
    ) {
      document.title = `Astra (${props.notifications?.totalCount || 0})`;
    }
  }, [props.notifications]);

  if (process.env.REACT_APP_REPORTS === "dev") {
    dispatch(setBiReportData(BiReportsDev))
    dispatch(setBiDashboardData(dashboardReportDev))
    dispatch(setVipBiReportData([VipBiReports[0]]))
  } else if (process.env.REACT_APP_REPORTS === "qa") {
    dispatch(setBiReportData(BiReportsQA))
    dispatch(setBiDashboardData(dashboardReportQA))
  } else if (process.env.REACT_APP_REPORTS === "prod") {
    dispatch(setBiReportData(BiReportsProd))
    dispatch(setBiDashboardData(dashboardReportProd))
    dispatch(setVipBiReportData([VipBiReports[1]]))

  }

  const CustomToast = ({ message, title }) => (
    <div>
      <p>{message}</p>
      <p>{title}</p>
    </div>
  );

  const notificationUpdate = (notification) => {
    dispatch(setSocketData(notification))
    dispatch(getPendingNotifications());
    var player = new Tone.Player({
      url: notty,
      autostart: true,
    }).toDestination();
    Tone.start();
    player.volume.value = 20;
    dispatch(setSocketNotification(true))
    const msg = notification.message
    const title = notification.title
    toaster.success(<CustomToast message={msg} title={title} />)
  }

  useEffect(() => {
    window.addEventListener("notificationUpdates",
      (e) => {
        notificationUpdate(e.detail);
      },
      false);
  }, [])

  useEffect(() => {
    const userID = userId ? userId : props?.loggedUserId;
    if (token && userID) subscribeNotification(userID);
  }, [token, userId])
  // useEffect(() => {
  //   const favicon = document.getElementById('favicon');
  //   if (starbetLogoPermission) {
  //     favicon.href = "/WAT_AFF.ico";
  //   } else {
  //     favicon.href = "/ne.svg";
  //   }
  // }, [starbetLogoPermission]);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     // Check if the event is a page refresh
  //     if (event && event.persisted) { //event.persisted wil check if the event is caused by page reload or not
  //       return;
  //     }
  //     console.log('User is leaving. Logging out...');
  //     // localStorage.clear();
  //     history.push("/login");
  //   };
  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === 'hidden' && localStorage.getItem('isNavigatingAway') !== 'true') {
  //       // The page is not visible (user is navigating away)
  //       localStorage.setItem('isNavigatingAway', 'true');
  //     } else if (document.visibilityState === 'visible' && localStorage.getItem('isNavigatingAway') === 'true') {
  //       // The page is visible again (user is coming back)
  //       localStorage.removeItem('isNavigatingAway');
  //       localStorage.clear();
  //     }
  //   };
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   document.addEventListener('visibilitychange', handleVisibilityChange);
  //   // Cleanup function
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  // }, []);


  const userPermissions = props.userPagePermissions.map(item => item.permissionName);
  return (
    <React.Fragment>
      <Toaster isSocket={props?.socketNotification} />
      <BrowserRouter>
        <Router>
          <div className="CMS-platform">
            <>
              <Switch>
                <Route
                  path="/"
                  exact
                  render={(props) =>
                    !token ? <Login {...props} cleanStore={handleLogOut} /> : <Redirect to={`/${props.defaultMainPath}`} />
                  }
                />
                <Route
                  exact
                  path="/login"
                  render={(props) =>
                    !token ? <Login {...props} cleanStore={handleLogOut} /> : <Redirect to={`/${props.defaultMainPath}`} />
                  }
                />
                <Route
                  path="/pinchange"
                  render={(props) => <AddNewPassword {...props} />}
                />
                <Route
                  path="/enabletotp"
                  render={(props) =>
                    token ? (
                      <Redirect to={`/${props.defaultMainPath}`} />
                    ) : props.totpEnabled === "" ? (
                      <Redirect to="/login" />
                    ) : (
                      <TotpAuth {...props} />
                    )
                  }
                />
                <Route
                  path="/authenticate"
                  render={(props) =>
                    token ? (
                      <Redirect to={`/${props.defaultMainPath}`} />
                    ) : props.totpEnabled === "" ? (
                      <Redirect to="/login" />
                    ) : (
                      <TotpLogin {...props} />
                    )
                  }
                />
                <>
                  <SubMenu />

                  <div className="CMS-layout-content">
                    <div className="CMS-layout-header">
                      <div className="CMS-header-left">
                        <div className="CMS-hambergur">
                          <span
                            className={props.open ? "material-icons cms-icon_hamburger active" : "material-icons cms-icon_hamburger"}
                            data-icon="menu"
                            id="CMS-hamburgerMenu"
                            onClick={handleSidebarClick}
                          ></span>
                        </div>
                        <a href="dashboard-new.html" className="logo-mobile">
                          {/* <img class="CMS-header-logo__brand" src={NElogo} alt="Logo" /> */}
                        </a>
                        <div className="CMS-search CMS-formControl-group"></div>
                      </div>
                      <div className="CMS-header-right">
                        <div
                          className="CMS-header-notification"
                          onClick={() => setNToggler(!nToggler)}
                          ref={nRef}
                        >
                          <span
                            className="material-icons"
                            data-icon="circle_notifications"
                          ></span>
                          {props?.notifications &&
                            Object.keys(props.notifications).length > 0 && (
                              <span className="CMS-notification-count">
                                {Object.keys(props.notifications).length > 0 &&
                                  props.notifications?.totalCount}
                              </span>
                            )}
                          <div
                            className={
                              nToggler ? "popper_box" : "popper_box close_popper"
                            }
                          >
                            <div className="popper_title">Notifications</div>
                            <div className="popper_content">
                              {props?.notifications &&
                                Object.keys(props.notifications).length > 0 &&
                                props.notifications?.totalCount &&
                                props.notifications?.totalCount > 0 ? (
                                <>
                                  {props?.notifications?.pendingDepositCount ?
                                    <Link
                                      to={"/pendingdeposits"}
                                      className="popper_content_box"
                                      onClick={() =>
                                        navigateToDestination("deposits")
                                      }
                                    >
                                      You have
                                      <span className="notification_count_link">
                                        {(props?.notifications &&
                                          props?.notifications
                                            ?.pendingDepositCount)}
                                      </span>
                                      pending deposits
                                    </Link> : ""
                                  }
                                  {props.notifications?.pendingWithdrawals ?
                                    <Link
                                      // to={"/pendingwithdrawal"}
                                      to={routeCheck()}
                                      className="popper_content_box"
                                      onClick={() =>
                                        navigateToDestination("withdrawals")
                                      }
                                    >
                                      You have
                                      <span className="notification_count_link">
                                        {props.notifications?.pendingWithdrawals}
                                      </span>
                                      pending withdrawals
                                    </Link> : ""
                                  }
                                  {props.notifications?.pendingAffiliateUserRequestCount ?
                                    <Link
                                      to={routeCheck()}
                                      className="popper_content_box"
                                      onClick={() =>
                                        navigateToDestination("becomeaffiliate")
                                      }
                                    >
                                      You have
                                      <span className="notification_count_link">
                                        {props.notifications?.pendingAffiliateUserRequestCount}
                                      </span>
                                      pending Affiliate Requests
                                    </Link> : ""
                                  }
                                </>
                              ) : (
                                <span className="popper_no_data">
                                  No notifications found
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-3">
                          <div className="CMS-select">
                            <select
                              value={
                                localStorage.getItem("merchantkey")
                                  ? localStorage.getItem("merchantkey")
                                  : brand
                              }
                              onChange={brandHandler}
                              id="selectBrand"
                              className="pointer"
                            >
                              {props.brandData &&
                                Array.isArray(props.brandData) &&
                                props.brandData?.length > 0 &&
                                props.brandData.map((item, index) => {
                                  return (
                                    <option key={index} value={item.merchantKey}>
                                      {item.name === "starpunter"
                                        ? "StarPunter"
                                        : item.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="CMS-logout">
                          <a href="/login" onClick={() => handleLogOut()}>
                            <button
                              className="CMS-btn CMS-btnQuaternary active CMS-btnMedium"
                              id="logout"
                            >
                              <span className="material-icons logout-icon" data-icon="logout"></span>
                              <span className="CMS-logoutText">{LOGIN_CONSTANTS.logout_btn}</span>
                            </button>
                          </a>
                        </div>
                        <div className="ml-10"></div>
                        {props.isAffiliateUser && props.starbetLogoPermission ? <div className="mb-10"><img src={starbetLogo} /></div> : ""}
                      </div>
                    </div>
                    <Switch>
                      <React.Fragment>
                        <div className="CMS-layout-innerContent">
                          <div className="CMS-page">
                            <div className="CMS-page-content">
                              <div className="CMS-full-page-content">
                                <NavigationTabs />
                                <div className="CMS-tabs-content">
                                  <ProtectedRoute
                                    userPermissions={userPermissions}
                                  />
                                  <NoPermissionRoutes />
                                  <Route
                                    path="/forbidden"
                                    render={(props) => <Forbidden {...props} />}
                                  />
                                  <Route
                                    path="/selflogout"
                                    render={(props) => <ForceLogout {...props} />}
                                  />
                                  <Route
                                    path="/bidashboard"
                                    exact
                                    render={(props) => <BiDashboard {...props} />}
                                  />
                                  <Route
                                    path="/bidashboard/:reportName"
                                    exact
                                    render={(props) => <AllDashboardReport {...props} />}
                                  />
                                  <Route
                                    path="/vip-reports/:reportName"
                                    exact
                                    render={(props) => <VipRepo {...props} />}
                                  />
                                  <Route
                                    path="/bireports"
                                    exact
                                    render={(props) => (
                                      <BiReport {...props} />
                                    )}
                                  />
                                  <Route
                                    path="/bireports/:categoryName/:reportName"
                                    exact
                                    render={(props) => <AllReport {...props} />}
                                  />

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </React.Fragment>

                    </Switch>
                  </div>
                </>
              </Switch>
            </>

            <footer id="footer"></footer>
          </div>
        </Router>
      </BrowserRouter>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    open: state.appslice.open,
    token: state.loginSlice.token,
    brandData: state.dashboard.brandData,
    seasonDropdown: state.dashboard.seasonDropdown,
    userPermission: state.dashboard.userPermission,
    userPagePermissions: state.dashboard.userPagePermissions,
    notifications: state.pendingWithrawal.notifications,
    totpEnabled: state.loginSlice.totpEnabled,
    openTabs: state.sidebar.openTabs,
    tabStack: state.sidebar.tabStack,
    defaultMainPath: state.dashboard.defaultMainPath,
    platformUserDetails: state.loginSlice.platformUserDetails,
    socketNotification: state.appslice.socketNotification,
    loggedUserId: state.appslice.loggedUserId,
    isAffiliateUser: state.loginSlice.isAffiliateUser,
    starbetLogoPermission: state.dashboard.starbetLogoPermission
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(
      {
        getEvenId,
      },
      dispatch
    ),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(App);

